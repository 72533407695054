import { styled } from '@mui/material/styles';

// Changes by - Aanchal Sahu on 4/18/23
// INFO: Commented dark themed icon since this component is used in pages before login(AuthResetPasswordSuccess) where we are only entertaining the light theme
export const AuthEmailSent = styled((theme) => {

  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
      <g mask="url(#mask0_1732_117109)">
        <path d="M57.75 96.5L32.875 71.75L36.5 68.125L57.75 89.375L103.5 43.625L107.125 47.25L57.75 96.5Z" fill="#403F3F" />
      </g>
    </svg>
  );

})``;

// Dark themed icon
// return (
//   <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
//     <g mask="url(#mask0_1732_120197)">
//       <path d="M57.75 96.5L32.875 71.75L36.5 68.125L57.75 89.375L103.5 43.625L107.125 47.25L57.75 96.5Z" fill="#F5F5F5" />
//     </g>
//   </svg>
// );