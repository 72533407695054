import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import useSettings from "../hooks/useSettings";
import { THEMES } from '../constants';
import { useSelector } from 'react-redux';


export const Logo = styled((props) => {
    const { variant, ...other } = props;
    const auth = useSelector(state => state.authReducer);//INFO: This logo is just to check if we have token since we have to set a light theme before login
    const settings = useSettings();
    
    const logoUrl = settings?.settings?.theme === THEMES.DARK && auth?.authtoken?
        '/assets/logo/entera-payroll-logo-dark.svg' : '/assets/logo/entera-payroll-logo-light.svg';


    return (

        <img alt='' width={"100%"} src={logoUrl} />

    );
})``;

Logo.defaultProps = {
    variant: 'primary'
};

Logo.propTypes = {
    variant: PropTypes.oneOf(['light', 'primary'])
};
