import { Errors } from "../BaseComponents/EToast";
import axios from "axios";
import { SCHEDULE_CALL_SLOT_FAILURE, SCHEDULE_CALL_SLOT_LOADING, SCHEDULE_CALL_SLOT_SUCCESS, SERVER_URL } from "../constants";

export function getScheduleCallSlot(token,params) {
    return (dispatch) => {
      dispatch(getScheduleCallSlotLoading());
      axios({       
        method: 'GET',
        url: `${SERVER_URL}/onboarding/get-available-slots?schedule_date=${params}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(getScheduleCallSlotSuccess(res.data))
          }
        }).catch((error) => {
          if (error?.response) {
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(getScheduleCallSlotFailure(error.response.data));
          }
        });
    };
    }
    
    export function getScheduleCallSlotLoading() {
      return {
        type: SCHEDULE_CALL_SLOT_LOADING,
      };
    }
    export function getScheduleCallSlotSuccess(payload) {  
      return {
        type: SCHEDULE_CALL_SLOT_SUCCESS,
        payload
      };
    }
    export function getScheduleCallSlotFailure(payload) {
      return {
        type: SCHEDULE_CALL_SLOT_FAILURE,
        payload
      };
    }
    