import { PAYROLL_REPORT_LIST_FAILURE, PAYROLL_REPORT_LIST_LOADING, PAYROLL_REPORT_LIST_SUCCESS } from '../constants';
import { axiosClient } from '../utils/AxiosClient';
import { Logout } from './AuthAction';

export function getPayrollReport(params) {
  return async (dispatch) => {
    try {
      dispatch(getPayrollReportLoading())
      const res = await axiosClient({
        url: `report/payroll`,
        params,
      })
      if (res.status === 200) {
        dispatch(getPayrollReportSuccess(res.data));
      }
    } catch (error) {
      if (error?.response) {
        dispatch(getPayrollReportFailure(error.response.data));
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    }
  };
}

export function getPayrollReportLoading() {
  return {
    type: PAYROLL_REPORT_LIST_LOADING,
  };
}

export function getPayrollReportSuccess(payload) {
  return {
    type: PAYROLL_REPORT_LIST_SUCCESS,
    payload: payload
  };
}

export function getPayrollReportFailure(payload) {
  return {
    type: PAYROLL_REPORT_LIST_FAILURE,
    payload
  };
}
