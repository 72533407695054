import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined, EButtonOutlinedIcon} from "../../BaseComponents/EButtons";
import Page from "../../BaseComponents/EPage";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { DEFAULT_ROWS_PERPAGE, PAGES, PENDING_STATUS, ROW_SIZE_PER_PAGE } from "../../constants";
import { EBoxPage } from "../../BaseComponents/EBox";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EChip from "../../BaseComponents/EChip";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { ContractListData } from "../../action/ContractAction";
import moment from "moment";
import base64 from 'base-64'
import { capitalizeCapitalString } from "../../utils/formatText";
import { EIcon } from "../../BaseComponents/EIcon";
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';

const TABLE_HEAD = [
    { id: 'SNo', label: 'SNo.', alignRight: 'center' },
    { id: 'Contract Name', label: 'Contract Name', alignRight: false },
    { id: 'Freelancer Name', label: 'Freelancer Name', alignRight: false },
    { id: 'Submitted On', label: 'Submitted On', alignRight: 'center' },
    { id: 'Deliverable', label: 'Deliverable', alignRight: 'center' },
    { id: 'Status', label: 'Status', alignRight: 'center' },
];

function InchargeList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    const [page, setPage] = useState(PAGES);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    // const [isDeleteContractTerm, SetIsDeleteContractTerm] = useState({ isOpen: false, id: '', name: '' });
    const contractList = useSelector(state => state.ContractReducer)

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(ContractListData(auth?.authtoken, {incharge_id: auth?.authData?.id}, page, rowsPerPage))  
        }
    }, [page, rowsPerPage])

    const HandleViewClick = (id) => {
        navigate(`/freelancer-contract/incharge-view/${base64.encode(id)}`)
    }

    const handleChangePage = (event,newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };

    return (
        <Page title="Incharge List">
            <Grid container display="row" justifyContent="space-between" >
                <EHeaderBreadcrumbs
                    heading='Incharge'
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Freelancer Contract', href: '' },
                        {
                            name: 'Incharge',
                        }
                    ]}
                />
                <EHidden width="mdDown">
                <Grid>
                    <EButtonOutlined className="button-left-margin" variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
                </EHidden>
                
            </Grid>
            <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Incharge</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow}  className='height-width-15px' /> </EButtonOutlinedIcon>
                </EHidden>
                <EHidden width="mdDown">
                <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>
               
            </Grid>
            <EBoxPage>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {contractList?.contractListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contractList?.contractListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!contractList?.contractListLoading && contractList?.contractListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contractList?.contractListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                />
                                <ETableBody>

                                    {contractList && contractList?.contractListData?.rows?.map((row, index) => {
                                        const { id, status, total_deliverables, completed_deliverables, Freelancer,submitted_on,
                                          contract_title,contract_code } = row;
                                        return (
                                            <ETableRow hover key={index + contract_code + id}  className='cursor-pointer' onClick={() => { HandleViewClick(id) }}>
                                               {/* Sno */}
                                                <ETableCell align="center" className="width-10px"> {index + 1}</ETableCell>
                                                
                                                  {/* Contract Name */}
                                                  <ETableCell sx={{ minWidth: '140px' }}>
                                                    <ETypography className=' text-capitalize bold-600 font-size-14px' >{contract_title ? contract_title : '-'}</ETypography>
                                                    {contract_code ? contract_code: ''}
                                                </ETableCell>

                                                 {/* Freelancer Date */}
                                                 <ETableCell >
                                                    <ETypography className=' text-capitalize bold-600 font-size-14px' onClick={() => navigate(`/contract/freelancer/edit/${Freelancer?.id}`)}> {Freelancer?.freelancer_name} </ETypography>
                                                    {Freelancer?.freelancer_code ?Freelancer?.freelancer_code: ''}
                                                </ETableCell>

                                                {/* Submitted On */}
                                                <ETableCell align="center" sx={{ minWidth: '150px' }}>
                                                    {submitted_on ? moment(submitted_on).format('DD/MM/YYYY') : '-'}
                                                </ETableCell>

                                                {/* Deliverable */}
                                                <ETableCell align="center" sx={{ minWidth: '110px' }}>
                                                    {completed_deliverables >= 0 ? completed_deliverables + '/' : '-'}
                                                    <span className="theme-color-static bold-700">{total_deliverables >= 0 ? total_deliverables : '-'}</span>
                                                </ETableCell>

                                                {/* Status */}
                                                <ETableCell align="center" className="width-10px">
                                                    <div className="display-flex">
                                                    {completed_deliverables >= 0 && total_deliverables >= 0 ?(
                                                        <>
                                                        <EChip label={capitalizeCapitalString(status)} width={'110px'} />
                                                        {status == PENDING_STATUS ? <EIcon icon={InfoIcon} className='grey-color' />:'' }
                                                        </>
                                                        
                                                       )  : '-'}
                                                    </div>
                                                  
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {!contractList?.contractListLoading && contractList?.contractListData?.rows?.length > DEFAULT_ROWS_PERPAGE ?
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={contractList?.contractListData?.count ? contractList?.contractListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
                {!contractList?.contractListLoading && contractList?.contractListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
            </EBoxPage>
        </Page>
    );
}


export default InchargeList