import React, { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { Logout } from "../action/AuthAction";


/** 
 * [2022-09-12]
 * Created by:- Aanchal Sahu
 * Description:- Made this function for auto logout if the user completes an idle state of half an hour * 
 **/
export default function IdleTimerComponent(props) {

  const { token } = props

  const dispatch = useDispatch()
  const idleTImerRef = useRef(null);

  const onIdle = () => {
    if (token != null) {
      dispatch(Logout(token))
    }
  }

  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTImerRef,
    timeout: 60000 * 15,
    onIdle: onIdle,
  });

  /**
   * 60000 Miliseconds = 1 minute
   * We are using 15 minuts of idle timer.
   */
  return <div idletimer={idleTimer}></div>

}

