import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------


/**
   * [2023-03-16]
   * Admin: Purva Sharma
   * Description:- Added Array for notification chips
   **/

EChip.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  sx: PropTypes.object,
  classname: PropTypes.string
};
// Note: ENTER THE LABEL IN ARRAY ONLY IN CAPITAL LETTERS
export default function EChip({ label, color = 'inherit', bgcolor = 'inherit',width=null,classname=' ',fontSize=null, ...sx }) {
  const uppercase_label = label ? label?.toUpperCase() : '';
  return (
    <Chip
      label={label}
      className={ classname +
       ( ['ENABLE', 'PAID', 'COMPLETED','COMPLETE', 'CLOSED', 'OWNER','ENABLED','LOCKED',].includes(uppercase_label) == true ?  //_GREEN
          'active-green-chip' :
          ['DISABLE', 'PENDING', 'HR PENDING','DISABLED'].includes(uppercase_label) == true ?  //_GREY
            'active-grey-chip' :
            ['APPROVED','HOLD','WORKING','RESPONSE'].includes(uppercase_label) == true ?            //_BLUE
              'active-blue-chip' :
              ['REJECT', 'REJECTED','CANCELLED','TERMINATED'].includes(uppercase_label) == true ? //_RED
                'active-red-chip' :
                    ['PARTIALLY APPROVED', 'PARTIALLY PAID','IN PROGRESS', 'ENTERA'].includes(uppercase_label) == true ? //_YELLOW
                      'active-warning-chip' :
                      ['P'].includes(uppercase_label) == true ?                // green attendance related elements
                        'attendance-green-chip' :
                        ['H'].includes(uppercase_label) == true ?                // blue attendance related elements
                          'attendance-blue-chip' :
                          ['A'].includes(uppercase_label) == true ?                // red attendance related elements
                            'attendance-red-chip' :
                            ['PL'].includes(uppercase_label) == true ?                // PINK attendance related elements
                              'attendance-pink-chip' :
                              ['HD'].includes(uppercase_label) == true ?                // Yellow attendance related elements
                                'attendance-yellow-chip' :
                                [ 'PARTIAL','VERIFIED',].includes(uppercase_label) == true ?                // Yellow attendance related elements
                                'orange-chip' :
                                ['PAYMENT','ATTENDANCE','CONTRACT','LEAVE','PAYROLL','NOTICE','USER MANAGEMENT','SUPPORT','SETTINGS','REMINDER','FREELANCER','BIRTHDAY'].includes(uppercase_label) == true ?                // Yellow attendance related elements
                                'notification-chip' :
                                'active-grey-chip '
                                
                                
                                )                                     // in here,please add new conditions if in need                                  
      }

      sx={{
        ...sx,
        fontSize:fontSize?fontSize:'0.8125rem',
        maxWidth: '10rem',
        width: width?width:'min-content',
        height: 'fit-content !important',
        borderRadius: "16px !important",
        fontWeight: 700,
        padding: '4px 4px',
        '& .MuiChip-label': {
          whiteSpace: 'break-spaces !important',
        },
      }}
    />
  );
}

export const EChipHrStats = styled(Chip)(({ theme }) => ({

  // padding: '0px 4px',
  margin:'4px 0px',
  // width: '100px',
  height: '28px',
  borderRadius: '2px',
}));




export const EChipStyled = styled(Chip)(({ theme }) => ({

}));