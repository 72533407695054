import { useEffect, useState } from 'react';
import ThemeConfig from './layouts/theme-config';
import GlobalStyles from './theme/globalStyles';
import Cookies from 'universal-cookie';
import LinearProgress from '@mui/material/LinearProgress';
import Router from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from './action/AuthAction';
import IdleTimerComponent from './PageComponents/IdleTimer';
import { Grid } from '@mui/material';
import { CompanySettingProvider } from './context/CompanySettingContext';

const cookies = new Cookies();
const token = cookies.get('secretToken');

function App() {
  
  const auth = useSelector(state => state.authReducer);
  
  return (
    <ThemeConfig token={auth.authtoken}>
      <GlobalStyles />
      <CompanySettingProvider token={auth.authtoken}>
        <MainApp />
      </CompanySettingProvider>
    </ThemeConfig>
  )
}

function MainApp() {

  const auth = useSelector(state => state.authReducer);
  const company = useSelector(state => state.CompanyReducer);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (token) {
      dispatch(verifyToken(token, () => setLoading(false)));
    } else {
      setLoading(false)
    }
  }, [token])

  return (

    <>
      {
        loading || auth?.authLoading || company.companyLoading 
          ?
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center", height: "100%",
              alignItems: "center", width: "100%"
            }}
          >
            <Grid sx={{ width: "20%" }}>
              <LinearProgress />
            </Grid>
          </Grid>
          :
          <>
            {/* IdleTimerComponent is here for auto logout if user is on idle state for 15 Minutes */}
            {auth.authtoken && <IdleTimerComponent token={auth.authtoken} />}
            {/* All the routing is in the Router Component */}
            <Router auth={auth} company={company} />
          </>
      }
    </>
  );
}

export default App;
