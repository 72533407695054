import React, { useState } from "react";
import {
    Grid, Stack, styled, IconButton,
    InputAdornment
} from "@mui/material";
import { EFieldLabelSemiBold, ETypography } from "../BaseComponents/ETypography";
import { ETextFieldLogin } from "../BaseComponents/ETextField";
import { EButton } from "../BaseComponents/EButtons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import { AuthEmailSent } from "../layouts/icons/auth-email-sent";
// import { AuthEmailInputIcon } from "../layouts/icons/auth-email-input-icon";

import { AuthKeyIcon } from "../layouts/icons/auth-key-icon";
// import { AuthLockIcon } from "../layouts/icons/auth-lock-icon";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { ResetPassAction } from "../action/AuthAction";
import { ResetPassSchema } from "../utils/validation";
import { ELinkMUI } from "../BaseComponents/ELink";

const AuthGrid = styled(Grid)(({ theme }) => (
    {
        textAlign: 'center',
    }));


const AuthResetPasswordForm = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);

  
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
            secretToken: params.key
        },
        validationSchema: ResetPassSchema,
        onSubmit: (data) => {
            dispatch(ResetPassAction(data, navigate));
        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;


    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    <AuthGrid>
                        <AuthKeyIcon />
                    </AuthGrid>

                    <AuthGrid >
                        <ETypography variant="h5" mb={0.5} mt={1.5} className="font-size-20px">
                            Create a strong password
                        </ETypography>
                        <ETypography>
                            Your password must have at least 8 characters and should include letters and numbers only.
                        </ETypography>
                    </AuthGrid>

                    <div className=" text-align-webkit-left pt-8px" >
                        <EFieldLabelSemiBold className="line-height-140perc">New Password</EFieldLabelSemiBold>
                        <ETextFieldLogin
                            fullWidth
                            autoComplete="off"
                            placeholder="Enter your new password"
                            type={showPassword ? 'text' : 'password'}

                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}

                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                    </div>

                    <div className=" text-align-webkit-left " >
                        <EFieldLabelSemiBold className="line-height-140perc ">Confirm Password</EFieldLabelSemiBold>
                        <ETextFieldLogin
                            fullWidth
                            placeholder="Confirm your new password"
                            autoComplete="false"
                            type={showConPassword ? 'text' : 'password'}
                            {...getFieldProps('confirm_password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowConPassword((show) => !show)} edge="end">
                                            {showConPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(touched.confirm_password && errors.confirm_password)}
                            helperText={touched.confirm_password && errors.confirm_password}
                        />
                    </div>

                    <EButton
                        fullWidth
                        size="large"
                        type="submit"
                        className="borderRadius-4px font-size-16px "
                    >
                        Reset Password
                    </EButton>
                    <ELinkMUI className="font-size-12px bold-400 text-align pt-18px mt-16px link-color" href="/login" >Back To Login </ELinkMUI> 
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default AuthResetPasswordForm;
