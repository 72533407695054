import React from 'react'
import { FormControlLabel } from '@mui/material'

import { ECheckbox } from '../../../BaseComponents/ECheckbox'
import { ETypography } from '../../../BaseComponents/ETypography'


export const PermissionCheckbox = ({ data, formik }) => {

  const { values, getFieldProps, handleChange } = formik
  const { permissions, existing_role } = values

  return (
    <FormControlLabel
      sx={{
        width: 'max-content'
      }}
      {...getFieldProps('permissions')}
      onChange={(e) => {
        handleChange(e)
      }}
      key={data?.permission}
      value={data?.permission}
      control={(
        <ECheckbox
          disabled={existing_role?.permissions?.includes(data?.permission) ?? false}
          checked={permissions?.includes(data?.permission) || false}
          sx={{
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      )}
      label={<ETypography>{data?.label}</ETypography>}
    />
  )
}
