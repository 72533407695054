import axios from "axios";
import { useState, useEffect } from "react";
import { GET_USER_IP_LINK } from "../constants";


const useIp = (isIP) => {
  const [checkIp, setData] = useState(null);
  const [ipLoader, setLoader] = useState(true);

  useEffect(() => {
    /**
     * Call the API when setting is available for the copmany
     * [isIP] varible containes ip of the company
     */
    if (isIP) {
      const timer = setTimeout(() => {
        const uninterceptedAxiosInstance = axios.create();
      uninterceptedAxiosInstance.get(GET_USER_IP_LINK)
          .then((res) => {
            return res.data;
          })
          .then((data) => {           
            setData(data)
          })
          .catch(() => setData(null))
          .finally(() => setLoader(false));
      }, 1000);
      return () => clearTimeout(timer);
    }else{
      setLoader(false)
    }
  }, [isIP]);

  // console.log(checkIp, ipLoader);
  return { checkIp, ipLoader };
};

export default useIp
