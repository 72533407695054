
import {
    USER_SETTING_FAILURE, USER_SETTING_LOADING, USER_SETTING_SUCCESS, 
    GLOBAL_USER_SETTING_LOADING, GLOBAL_USER_SETTING_SUCCESS, GLOBAL_USER_SETTING_FAILURE
} from '../constants';


const INITIAL_STATE = {
    // INFO: the following variables are for set/get data from settings apis
    userSettingData: [],
    userSettingSuccess: false,
    userSettingLoading: false,
    userSettingMessage: null,

    // INFO: the following variables are storing global user setting data 
    globalUserSettingData: [],
    globalUserSettingSuccess: false,
    globalUserSettingLoading: false,
    globalUserSettingMessage: null,

};

const UserSettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_SETTING_LOADING:
            return {
                ...state,
                userSettingLoading: true,
                userSettingData: action.payload
            };

        case USER_SETTING_SUCCESS:
            return {
                ...state,
                userSettingLoading: false,
                userSettingSuccess: true,
                userSettingData: action.payload.data,
                userSettingMessage: action.payload.message,
            };

        case USER_SETTING_FAILURE:
            return {
                ...state,
                userSettingLoading: false,
                userSettingSuccess: false,
                userSettingMessage: action.payload.message,
                userSettingData: action.payload,
            };
        case GLOBAL_USER_SETTING_LOADING:
            return {
                ...state,
                globalUserSettingLoading: true,
                globalUserSettingData: [],
            };

        case GLOBAL_USER_SETTING_SUCCESS:
            return {
                ...state,
                globalUserSettingLoading: false,
                globalUserSettingSuccess: true,
                globalUserSettingData: action.payload.data,
                globalUserSettingMessage: action.payload.message,
            };

        case GLOBAL_USER_SETTING_FAILURE:
            return {
                ...state,
                globalUserSettingLoading: false,
                globalUserSettingSuccess: false,
                globalUserSettingMessage: action.payload.message,
                globalUserSettingData: [],
            };

        default:
            return state;
    }
};

export default UserSettingReducer;
