import axios from "axios";
import { Success, Errors } from "../BaseComponents/EToast";
import { SERVER_URL } from "../constants";

export function CreateFeatureRequest(token, Data, close = null) {
  const data = new FormData();
  data.append("feature", Data.feature);
  data.append("description", Data.description);
  data.append("category_id", Data.category_id);
  data.append("feature_file", Data.feature_file);
  return (dispatch) => {
    axios({
      method: "post",
      url: `${SERVER_URL}/feature/create`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
          if (close) {
            close();
          }
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          });

          if (close) {
            close();
          }
        }
      });
  };
}
