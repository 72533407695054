import { Grid } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, EIconButton, ELoadingButton } from "../../BaseComponents/EButtons";
import { EIcon } from "../../BaseComponents/EIcon";
import EModal from "../../BaseComponents/EModal";
import Page from "../../BaseComponents/EPage";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { DEFAULT_ROWS_PERPAGE, PAGES, PERMISSIONS, ROW_SIZE_PER_PAGE } from "../../constants";
import { EBoxPage } from "../../BaseComponents/EBox";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EChip from "../../BaseComponents/EChip";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { ContractListData, DeleteContract } from "../../action/ContractAction";
import moment from "moment";
import base64 from 'base-64'
import { capitalizeCapitalString } from "../../utils/formatText";
import { numberWithCommas } from "../../utils/formatNumber";
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import BackIcon from '@iconify/icons-material-symbols/chevron-left-rounded'
import EyeIcon from '@iconify/icons-material-symbols/visibility-outline-rounded'
import AddIcon from '@iconify/icons-material-symbols/handshake-outline'
import ContractSearchForm from "../../PageComponents/FreelancerContracts/Contracts/ContractSearchForm";
import EHidden from "../../BaseComponents/EHidden";
import { useCheckPermission } from "../../hooks";


const getTableHead = (allowAction) => {
    const arrayHead = [
        { id: 'Contract', label: 'Contract', alignRight: false },
        { id: 'Freelancer Name', label: 'Freelancer Name', alignRight: false },
        { id: 'Tenure', label: 'Tenure', alignRight: 'center' },
        { id: 'Amount', label: 'Amount', alignRight: true },
        { id: 'Status', label: 'Status', alignRight: 'center' },
    ];
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead;
};

function ContractList(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    const Theme = useSelector(state => state.ThemeReducer)
    const [page, setPage] = useState(PAGES);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [isDeleteContractTerm, SetIsDeleteContractTerm] = useState({ isOpen: false, id: '', name: '' });
    const [SearchedValues, setSearchedValues] = useState({ name: '', sort: '',year_month:'' });
    const contractList = useSelector(state => state.ContractReducer)
    
    const allowAddEditDelete = useCheckPermission([PERMISSIONS.ViewAddEditDeleteContract])
    const allowTerminateContract = useCheckPermission([PERMISSIONS.ViewTerminateContract])
    
    const TABLE_HEAD = useMemo(() => getTableHead(allowAddEditDelete || allowTerminateContract), [])

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(ContractListData(auth?.authtoken, SearchedValues, page, rowsPerPage))
        }
    }, [page, rowsPerPage, SearchedValues])

    const HandleViewClick = (id) => {
        if (!(allowAddEditDelete || allowTerminateContract)) return
        navigate(`/freelancer-contract/view-contract/${base64.encode(id)}`)
    }
    const HandleContractDelete = () => {
        dispatch(DeleteContract(auth?.authtoken, { contract_id: isDeleteContractTerm.id }, CloseDeleteModal))
    }
    const CloseDeleteModal = () => {
        SetIsDeleteContractTerm({ ...isDeleteContractTerm, isOpen: false, id: null, name: '' })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };

    const onSearch = (value) => {
        setSearchedValues(value)
        setPage(0, page, rowsPerPage);
    }


    return (
        <Page title="Contract List">
            <Grid container display="row" justifyContent="space-between" >
                <EHeaderBreadcrumbs
                    heading='Contract List'
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Freelancer Contract', href: '' },
                        {
                            name: 'Contract List',
                        }
                    ]}
                />
                <EHidden width="mdDown">
                    <Grid>
                        {
                            allowAddEditDelete &&
                            <EButton variant="contained" to="/freelancer-contract/add-contract" component={RouterLink}  > Add Contract</EButton>
                        }
                        <EButtonOutlined className="button-left-margin" variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
                
            </Grid>

            <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Contract List</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid>
                        {
                            allowAddEditDelete &&
                            <EButtonIcon variant="contained" to="/freelancer-contract/add-contract" component={RouterLink}  ><EIcon icon={AddIcon} className='height-width-15px'/></EButtonIcon>
                        }
                        <EButtonOutlinedIcon className="button-left-margin" variant="outlined" onClick={() => navigate(-1)} >  <EIcon icon={BackIcon} className='height-width-15px'/> </EButtonOutlinedIcon>
                    </Grid>

                </EHidden>
                <EHidden width="mdDown">
                <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>
            
            </Grid>
            <EBoxPage>
                <ContractSearchForm search={SearchedValues} setSearch={onSearch} auth={auth} />
                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {contractList?.contractListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contractList?.contractListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!contractList?.contractListLoading && contractList?.contractListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contractList?.contractListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />
                                <ETableBody>

                                    {contractList && contractList?.contractListData?.rows?.map((row, index) => {
                                        const { id, status,total_amount, total_deliverables, completed_deliverables, Freelancer, contract_start_date, contract_end_date, contract_title, is_locked, contract_code } = row;
                                       
                                        return (
                                            <ETableRow hover key={index + contract_code + id}  className={(allowAddEditDelete || allowTerminateContract) && 'cursor-pointer'} onClick={() => { HandleViewClick(id) }}>

                                                {/* Contract */}
                                                <ETableCell align="left" sx={{ minWidth: '150px' }} >
                                                    <ETypography className='bold-700 text-capitalize' > {contract_title} </ETypography>
                                                   {contract_code}
                                                </ETableCell>

                                                {/* Freelancer Name */}
                                                <ETableCell align="left" sx={{ minWidth: '150px' }} >
                                                    <ETypography className='bold-700 text-capitalize' > {Freelancer?.freelancer_name} </ETypography>
                                                    {Freelancer?.freelancer_code ? Freelancer?.freelancer_code : ''}
                                                </ETableCell>

                                                {/* Tenure */}
                                                <ETableCell align='center' >
                                                    {moment(contract_start_date).format('DD/MM/YYYY') + ' to ' + moment(contract_end_date).format('DD/MM/YYYY')}
                                                </ETableCell>


                                                {/* Amount */}
                                                <ETableCell align="right" className="minWidth-150" >
                                                    {total_amount ? numberWithCommas(total_amount): '-'}
                                                </ETableCell>


                                                {/* Status */}
                                                <ETableCell align="center" >
                                                    {completed_deliverables >= 0 && total_deliverables >= 0 ?
                                                        <EChip  label={capitalizeCapitalString(status)} width={'110px'} /> : '-'}
                                                </ETableCell>

                                                {/* Action */}
                                                {
                                                    (allowAddEditDelete || allowTerminateContract) &&
                                                    <ETableCell align="center" style={{ width: "20px", paddingRight: '0' }}>
                                                    {
                                                        allowAddEditDelete && !is_locked
                                                            ?
                                                            <Grid container sx={{ flexWrap: 'nowrap' }}>
                                                                <Grid item>
                                                                    <EIconButton className="p0"  >
                                                                        <EIcon icon={editFill} className='blue-color   ' width={24} height={24} />
                                                                    </EIconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <EIconButton className="p0" onClick={() => { SetIsDeleteContractTerm({ isOpen: true, id: id, name: contract_title + ' (' + contract_code + ')' }) }}>
                                                                        <EIcon icon={DeleteIcon} sx={{ color: 'text.redColor' }} width={24} height={24} />
                                                                    </EIconButton>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <EIcon onClick={() => { HandleViewClick(id) }} icon={EyeIcon} width={24} height={24} />
                                                    }
                                                    </ETableCell>
                                                }
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}


                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {!contractList?.contractListLoading && contractList?.contractListData?.count >DEFAULT_ROWS_PERPAGE ?
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={contractList?.contractListData?.count ? contractList?.contractListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
                {!contractList?.contractListLoading && contractList?.contractListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
            </EBoxPage>

            {/* ______________This is a contract delete modal______________ */}
            {isDeleteContractTerm.isOpen && (
                <EModal open={isDeleteContractTerm.isOpen} close={() => CloseDeleteModal()} headervalue="Delete Contract: Confirmation">
                    <Grid container spacing={2} >
                        <Grid item>
                            <ETypography variant="h5" className="font-size-18px "> Are you sure you want to delete
                                <span className="theme-main-text-color-bold">{isDeleteContractTerm.name ? " " + isDeleteContractTerm.name : 'this contract?'}</span> ? </ETypography>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <EButtonOutlined
                                size="large"
                                variant="outlined"
                                onClick={() => { CloseDeleteModal() }}
                            >
                                {' '}
                                No{' '}
                            </EButtonOutlined>
                            <ELoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={HandleContractDelete}
                                className='button-left-margin'
                            >
                                Yes
                            </ELoadingButton>
                        </Grid>
                    </Grid>
                </EModal>
            )}
        </Page>
    );
}


export default ContractList
