import React from 'react'
import { Button,  Box, Slider,
} from '@mui/material';
import { connect } from "react-redux";
import AvatarEditor from 'react-avatar-editor'
import leftArrow from '@iconify/icons-material-symbols/arrow-left'
import rightArrow from '@iconify/icons-material-symbols/arrow-right'
// import leftArrow from '@iconify/icons-mdi/arrow-u-left-top'
// import rightArrow from '@iconify/icons-mdi/arrow-u-right-top'
import check from '@iconify/icons-material-symbols/check'
import Close from '@iconify/icons-material-symbols/close'
import { Icon } from '@iconify/react';
/**
 * [2022-03-21]
 * Author:-AanchaL Sahu
 * Description:-For the image editing, demo of parameters this function should get:
   const [picture, setPicture] = useState({ cropperOpen: false,  img: null, zoom: 1, rotate: 0, croppedImg: "",  croppedImgFileObject:''  });
   const [editor, setEditor] = useState("");
   [2022-04-11]
  * Changes By: Aanchal Sahu
  * Description:- changes  to solve image upload issue
 **/
const ImageEditing = (props) => {
    const { picture,setPicture ,editor,setEditor} = props
 
    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value
        });
    };
    const handleRotateLeft = (event, value) => {
        setPicture({
            ...picture,
            rotate: picture.rotate - 90
        });
    };
    const handleRotateRight = (event, value) => {
        setPicture({
            ...picture,
            rotate: picture.rotate + 90
        });
    };

    const handleCancel = () => {
       if (picture.photostatus){
        setPicture({
            ...picture,
            cropperOpen: false,
            photostatus:'cancel'
        });}
        else {
            setPicture({
                ...picture,
                cropperOpen: false,
                newformphotostatus:'cancel',
                croppedImg:''
            });}
        
    };

    const setEditorRef = (ed) => {
        setEditor(ed)
    };

    const handleSave = async (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            let CroppedFileObject = await fetch(croppedImg)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], picture.img.name, { type: picture.img.type }); })
            if(picture.photostatus){
                setPicture({
                ...picture,
                img: null,
                cropperOpen: false,
                croppedImg: croppedImg,
                croppedImgFileObject:CroppedFileObject,
                photostatus:'save'
            });}
            else{
                setPicture({
                    ...picture,
                    img: null,
                    cropperOpen: false,
                    croppedImg: croppedImg,
                    croppedImgFileObject:CroppedFileObject,
                    newformphotostatus:'save'
                })
            }
        }
    };

    return (
        <Box >
            <Box display="block">
                <AvatarEditor
                    ref={setEditorRef}
                    image={picture.img}
                    width={150}
                    height={150}
                    border={50}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={picture.zoom}
                    rotate={picture.rotate}
                />
                <Slider
                    aria-label="raceSlider"
                    value={picture.zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSlider}
                ></Slider>
                <Box>
                    <Button onClick={handleRotateRight}> <Icon icon={leftArrow} width={20} height={20} /> </Button>
                    <Button onClick={handleRotateLeft}> <Icon icon={rightArrow} width={20} height={20} /> </Button>
                    <Button onClick={handleSave}> <Icon icon={check} width={20} height={20} /> </Button>
                    <Button onClick={handleCancel}><Icon icon={Close} width={20} height={20} /></Button>
                </Box>
            </Box>
        </Box>
    )
}
const mapStateToProps = (state) => (
    {
        auth: state.authReducer,
    }
)
export default connect(mapStateToProps)(ImageEditing)


