import axios from "axios";
import { Success, Errors } from "../BaseComponents/EToast";
import { SERVER_URL } from "../constants";
import { CompanyDetail } from "./CompanyAction";

export function InviteHRAction(token, Data, close) {

  const data = new FormData();
  data.append("fname", Data.fname);
  data.append("lname", Data.lname);
  data.append("mobie_no", Data.contact_number);
  data.append("email_id", Data.email_id);
  return (dispatch) => {
    axios({
      method: "post",
      url: `${SERVER_URL}/onboarding/invite-hr`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
          dispatch(CompanyDetail(token))
          if (close) {
            close();
          }
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          });

          if (close) {
            close();
          }
        }
      });
  };
}
