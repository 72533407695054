import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import { SettingsProvider } from './context/SettingsContext';
import axios from 'axios';

axios.interceptors.request.use(function (config) {
  config.headers['X-Request-At'] = new Date();
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <Provider store={store} >
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
