import axios from "axios";
import { Success, Errors } from "../BaseComponents/EToast";
import { ROLE_HR, SERVER_URL, SUPER_ADMIN } from "../constants";
import { onBoardingStepAction } from "./OnboardingStepAction";

export function ScheduleACallAction(token, Data,navigate, company, role) {
  const data = new FormData();
  data.append("fullname", Data.fullname);
  data.append("contact_number", Data.contact_number);
  data.append("email_id", Data.email_id);
  data.append("scheduled_date_time", Data.scheduled_date_time);
  return (dispatch) => {
    axios({
      method: "post",
      url: `${SERVER_URL}/onboarding/schedule-call`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          if(role==SUPER_ADMIN && company?.companyData?.onBoarding?.explore_welcome_initial_category_superadmin == null)
          {
            navigate('/thankyou-for-response')
          }

          else if(role==ROLE_HR && company?.companyData?.onBoarding?.explore_welcome_initial_category_hr == null)
          {
            navigate('/thankyou-for-response')
          }

          else{
            dispatch(onBoardingStepAction(token, 'SCHEDULE'))
            navigate('/')
          }
         
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          });

       
        }
      });
  };
}
