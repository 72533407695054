import axios from 'axios';
import { SERVER_URL, UNKNOWN_ERROR } from '../constants';
import Cookies from 'universal-cookie';
import { Errors } from '../BaseComponents/EToast';

export const axiosClient = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

axiosClient.interceptors.request.use(
  async config => {
    const cookies = new Cookies();
    const token = cookies.get('secretToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.code !== "ERR_CANCELED" && !error?.response?.data?.message) {
      Errors.fire({
        text: 'html',
        html: `<span style="color:white">${UNKNOWN_ERROR}</span>`
      })
    }
    return Promise.reject(error);
  }
);
