import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ECardBasic } from './ECard';
import { ECustomizedRadioWithTitle } from './ECustomizedRadioWithTitle';

/**
 * INFO: This component provides a custom collapsible grid that have title and/or subtitle and children with radio button on right
 */
export const ECollapsibleGrid = (props) => {

  const { label, extraLabel, name, labelClassName = "", value, onChange = () => { }, error = "", options = [], expanded = false, children, disabled = false, ...gridProps } = props;

  return (
    <>
      <Grid {...gridProps}>
        <ECardBasic className='mb-0px border-05px-border6 '>
          <Grid container>
            <ECustomizedRadioWithTitle
              label={label}
              labelClassName={labelClassName}
              extraLabel={extraLabel}
              options={options}
              value={value}
              name={name}
              onChange={onChange}
              error={error}
              disabled={disabled}
            />
            {!!expanded && children}
          </Grid>
        </ECardBasic>
      </Grid>
    </>
  );
};

