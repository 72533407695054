import {
  CONTRACT_LIST_LOADING,
  CONTRACT_LIST_SUCCESS,
  CONTRACT_LIST_FAILURE,
  CONTRACT_LOADING,
  CONTRACT_SUCCESS,
  CONTRACT_FAILURE
} from '../constants';

const INITIAL_VALUE = {
  contractListData: {},
  contractListSuccess: false,
  contractListLoading: false,
  contractListMessage: null,
  contractData: {},
  contractSuccess: false,
  contractLoading: false,
  freelanceMessage: null
};

const ContractReducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    case CONTRACT_LIST_LOADING:
      return {
        ...state,
        contractListLoading: true,
        contractListData: {},
        contractListSuccess: false,
        contractListMessage: null
      };
    case CONTRACT_LIST_SUCCESS:
      return {
        ...state,
        contractListLoading: false,
        contractListSuccess: true,
        contractListData: action.payload.data,
        contractListMessage: action.payload.message
      };

    case CONTRACT_LIST_FAILURE:
      return {
        ...state,
        contractListLoading: false,
        contractListSuccess: false,
        contractListData: {},
        contractListMessage: action.payload.message
      };
    case CONTRACT_FAILURE:
      return {
        ...state,
        contractLoading: false,
        contractSuccess: false,
        contractData: {},
        contractMessage: action.payload.message
      };
    case CONTRACT_LOADING:
      return {
        ...state,
        contractLoading: true,
        contractData: {},
        contractSuccess: false,
        contractMessage: null
      };
    case CONTRACT_SUCCESS:
      return {
        ...state,
        contractLoading: false,
        contractSuccess: true,
        contractData: action.payload.data,
        contractMessage: action.payload.message
      };
    default:
      return state;
  }
};

export default ContractReducer;
