import * as React from 'react';
import Menu from '@mui/material/Menu';
import MoreVert from '@iconify/icons-material-symbols/more-vert'
import { EIcon } from '../../BaseComponents/EIcon';
import { EIconButton } from '../../BaseComponents/EButtons';

const ITEM_HEIGHT = 48;

export default function ActionMenu(props) {
    
    const { children } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        // clickEventFun();
    };
    return (
        <div>

            <EIconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <EIcon icon={MoreVert} />
            </EIconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {children}   
            </Menu>
        </div>
    );
}