import { Step, StepLabel, Stepper, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const EStepper = styled(Stepper)(({ theme }) => ({
    // INFO: The following width , left & position is used for the change in 100% width due to alternativeLabel (for modals).
    width: 'calc(100% + 20px + 6.9rem)',
    left: 'calc(-3.9rem)',
    position: 'absolute'
}));

export const EStep = styled(Step)(({ theme }) => ({
}));

// This is the label of the stepper
export const EStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel, .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        marginTop: 0
    },
    '& .MuiStepLabel-label.Mui-completed': { //INFO: This the the label color for the completed steps.
        color: theme.palette.primary.main
    },
    '& .MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-disabled': { //INFO: This the the label color for the disabled & active steps.
        color: theme.palette.text.greyColor4,
        fontWeight: 400

    },
}));

// EConnector is the line that connects the Stepper
export const EConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    //INFO: The three states of a stepper are: disabled, active & completed, according to which the following state is targeted.
    [`&.${stepConnectorClasses.completed}`]: {//INFO: Condition for completed stepper line
        [`& .${stepConnectorClasses.line}`]: {
            border: `1px solid ${theme.palette.primary.main}`
        },
    },
    [`& .${stepConnectorClasses.line}`]: { //INFO: Standard css for stepper line
        border: `1px dotted ${theme.palette.border.staticBorder7}`
    },
}));

//INFO: The following function is responsible for the icon management in a stepper.
export function EStepIcon(props) {
    const { active, completed, icon } = props;
    return (
        <div ownerstate={{ active }} className={completed ? "line-height-0px" : 'line-height-24px'}>
            {completed ? (
                <CheckCircleOutlineIcon className="theme-color-static m-none line-height-0px" />
            ) : (
                <div className="height-width-22px text-align greyColor10-bg borderRadius-20px color-text-primary font-size-14px">{icon}</div>
            )}
        </div>
    );
}