import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

export const EAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: "transparent",
    border: `1px solid transparent`,
    boxShadow:'0 0 0 0px !important',
    borderBottom:'1px solid rgba(217, 121, 4, 0.5)',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded':{
        backgroundColor: theme.palette.background.activeAccordian,
        '.MuiAccordionSummary-content':{
            h6:{
                fontSize:'1rem',
                fontWeight: 700
            },
        },
    }
}));

export const EAccordion2 = styled(Accordion)(({ theme }) => ({
    border: '0.5px solid #9C9B9A',
    background: theme.palette.background.card,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px !important',
    padding:'0 16px',
    '&:before': {
        display: 'none',
    },
    margin:'16px 0 0 0',
    '&.Mui-expanded':{
        backgroundColor:theme.palette.background.card4,
    }
}));


export const EAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight:'16px',
    padding:'16px',
    
    h6:{
        fontWeight: 400,
        fontSize:'1rem',
    },
}));

export const EAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    paddingTop:'0px !important',
    span:{
        fontSize:'0.906rem', //'14.5px',
    }
}));