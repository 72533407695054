import axios from 'axios';
import { Errors } from '../BaseComponents/EToast';
import { CompanyDetail } from './CompanyAction';
import {  SERVER_URL } 
from '../constants';




export function onBoardingStepAction(token,Data,navigate) {
    const data = new FormData();
    data.append('explore_welcome_initial_category', Data);
   

    return (dispatch) => {
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/complete-explore`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(CompanyDetail(token));
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
        }
      });
  };
}