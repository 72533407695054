import React from "react";
import { Container } from "@mui/material";
import LoginForm from "../../PageComponents/AuthLoginForm";
import AuthPage from "../../BaseComponents/EAuthPage";
import { AuthNavbar } from "../../layouts/auth-navbar";
import { ETypography } from "../../BaseComponents/ETypography";
import { AuthContentStyle, AuthRootStyle } from "../../BaseComponents/EContainer";
import { useLocation } from "react-router";

const Login = () => {
  const location = useLocation()

  return (
    <AuthPage title="Login">
      <AuthNavbar />
      <AuthRootStyle maxWidth="lg">
        <Container maxWidth="sm" className="text-align-webkit-center ">

          <AuthContentStyle elevation={24} className="grayColor2-color-border">
            <LoginForm location = {location} />
          </AuthContentStyle>

          <AuthContentStyle className="p-18px bg-transparent">
          <ETypography className="font-size-12px bold-400 text-align">
              This site is protected by reCAPTCHA and the
              <a
                href="https://policies.google.com/privacy"
                className="link-color"
              >
                {" "}
                Google Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms"
                className="link-color"
              >
                Terms of Service{" "}
              </a>{" "}
              apply.
            </ETypography>
          </AuthContentStyle>

        </Container>
      </AuthRootStyle>
    </AuthPage>
  );
};

export default Login;
