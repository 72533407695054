import {
  REGULARIZATION_REQUEST_LIST_FAILIURE, 
  REGULARIZATION_REQUEST_LIST_SUCCESS, 
  REGULARIZATION_REQUEST_LIST_LOADING,
  } from '../constants';
  
  const INITIAL_STATE = {
    regularizationListData: {},
    regulariSuccess: false,
    regularizeLoading: false,
    regularizeMessage: null,
  };
  
  const RegularizationRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      // FOR regularize LIST 
  
      case REGULARIZATION_REQUEST_LIST_LOADING:
        return {
          regularizeLoading: true,
          regularizationListData: {},
        };
  
      case REGULARIZATION_REQUEST_LIST_SUCCESS:
        return {
          regularizeLoading: false,
          regularizeSuccess: true,
          regularizationListData: action.payload.data,
          regularizeMessage: action.payload.message,
        };
  
      case REGULARIZATION_REQUEST_LIST_FAILIURE:
        return {
          regularizeLoading: false,
          regularizeSuccess: false,
          regularizeMessage: action.payload.message,
          regularizationListData: {},
        };
  
  
      default:
        return state;
    }
  };
  
  export default RegularizationRequestReducer;
  