import {
    UPDATE_PROFILE_LOADING,UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_ADD_SUCCESS, UPDATE_PROFILE_ADD_FAILURE, UPDATE_PROFILE_ADD_LOADING
} from '../constants';


const INITIAL_STATE = {
  updateprofileData: {},
  updateprofileSuccess: false,
  updateprofileLoading: false,
  updateprofileMessage: null,
  updateprofileAddData: [],
  updateprofileAddSuccess: false,
  updateprofileAddLoading: false,
  updateprofileAddMessage: null,
};

const UpdateProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_LOADING:
      return  {
        updateprofileLoading: true,
        updateprofileData: {},
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        updateprofileLoading: false,
        updateprofileSuccess: true,
        updateprofileData: action.payload.data,
        updateprofileMessage: action.payload.message,
      };

    case UPDATE_PROFILE_ADD_FAILURE:
      return  {
        updateprofileLoading: false,
        updateprofileSuccess: false,
        updateprofileMessage: action.payload.message,
        updateprofileData: {},
      };

      case UPDATE_PROFILE_ADD_LOADING:
      return  {
        updateprofileAddLoading: true,
        updateprofileAddData: [],
      };

    case UPDATE_PROFILE_ADD_SUCCESS:
      return {
        updateprofileAddLoading: false,
        updateprofileAddSuccess: true,
        updateprofileAddData: action.payload.data,
        updateprofileAddMessage: action.payload.message,
      };

      
    default:
      return state;
  }
};

export default UpdateProfileReducer;
