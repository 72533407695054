import React from 'react';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
// material

import { last } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Breadcrumbs, Grid } from '@mui/material';
import { ETypography, ETypographyBreadCrumb } from './ETypography';
import { EIcon } from './EIcon';
import BreadcrumIcon from '@iconify/icons-material-symbols/chevron-right-rounded'
import EHidden from './EHidden';

// import { MBreadcrumbs } from './@material-extend';

/**
   * [2022-03-01]
   * Admin: Purva Sharma
   * Description:-Hiding Breadcrumbs from 600 screens
   **/


// ----------------------------------------------------------------------

EHeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object
};

export default function EHeaderBreadcrumbs({ links, action, heading, moreLink = '', extraParam = '' || [], sx, ...other }) {
  return (
    <EHidden width="mdDown">
   <Box sx={{  display: 'flex', alignItems: 'center' , ...sx }}  >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid sx={{ display: 'flex'}} flexDirection={{xs:'column',sm:'row',md:'row',lg:'row',xl:'row'}} >
          <ETypographyBreadCrumb variant="body1"  pr={1} color='primary' >
            {heading} | {' '}
          </ETypographyBreadCrumb>
          <MBreadcrumbs links={links} {...other} />
        </Grid>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
        {extraParam && <Box sx={{ flexShrink: 0 }}><ETypography variant='h6'>{extraParam}</ETypography></Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2" >
            <ETypography>{moreLink}</ETypography>
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link noWrap key={href} href={href} variant="body2" target="_blank" sx={{ display: 'table',color:'primary' }}>
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
    </EHidden>
    
  );
}

// ==========================================================


// ----------------------------------------------------------------------


LinkItem.propTypes = {
  link: PropTypes.object
};

function LinkItem({ link }) {
  const { href, name, icon } = link;
  return (
    <Link 
      to={href}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.disabled', 
        '& > div': { display: 'inherit' }
      }}
    >
      {icon && (
        <Box 
          sx={{
            mr: 1,
            '& svg': { width: 20, height: 24},
            
          }}
        >
          {icon}
        </Box>
      )}
      <ETypography className='font-size-14px' sx={{color:'chip.secondaryText'}}>{name}</ETypography>
    </Link>
  );
}

MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool
};

function MBreadcrumbs({ links, activeLast = false, ...other }) {
  const currentLink = last(links).name;

  const listDefault = links.map((link) => <LinkItem  key={link.name} link={link} 
  sx={{fontSize:'0.875rem !important',
  color:'red !important',
  'p':{ 
    fontSize:'0.875rem !important'//14px
  }}}/>);
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link}
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}

        />
      ) : ( 
        <ETypography 
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color:'chip.secondaryText'
          }}
        >
          {currentLink}
        </ETypography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={ <EIcon icon={BreadcrumIcon}  sx={{height:24, width:20,color:'chip.secondaryText'}}/>}  {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}
