import axios from 'axios';
import {
  SERVER_URL,
  NOTIFICATION_LIST_FAILURE,
  NOTIFICATION_LIST_LOADING,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_CATEGORY_LIST_LOADING,
  NOTIFICATION_CATEGORY_LIST_SUCCESS,
  NOTIFICATION_CATEGORY_LIST_FAILURE,
  PAGES,
  DEFAULT_ROWS_PERPAGE_5,
  GET_NOTIFICATION_LIST_API
} from '../constants';
import { Logout } from './AuthAction';

// INFO: as of now, the default count we want in the notification reducer is that 
export function NotificationList(token, Data, page = PAGES, pageSize = DEFAULT_ROWS_PERPAGE_5) {

  let queryString = '';

  if (page !== null && pageSize !== null) {
    queryString += `?page=${page}&per_page=${pageSize}`;
  }

  if (Data?.sort) {
    queryString += `&sort=${Data?.sort}`;
  }

  return (dispatch) => {
    dispatch(notificationLoading(Data));
    axios({
      method: 'get',
      url: `${SERVER_URL}/${GET_NOTIFICATION_LIST_API}${queryString}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(notificationSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(notificationFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function notificationLoading(payload) {
  return {
    type: NOTIFICATION_LIST_LOADING,
    payload
  };
}
export function notificationSuccess(payload) {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    payload
  };
}

export function notificationFailure(payload) {
  return {
    type: NOTIFICATION_LIST_FAILURE,
    payload
  };
}

// NOTIFICATION READ/UNREAD

export function NotificationReadUnread(token,id) {
  let data = new FormData()
  data.append('notification_id',id)
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${SERVER_URL}/notification/notification-read`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data:data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(NotificationList(token))
        }
      })
      .catch((error) => {
        if (error?.response) {
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


// NOTIFICATION CATEGORY

export function NotificationCategoryList(token) {
  return (dispatch) => {
    dispatch(notificationCategoryLoading([]));
    axios({
      method: 'get',
      url: `${SERVER_URL}/notification/notification-category-list`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(notificationCategorySuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(notificationCategoryFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
export function notificationCategoryLoading(payload) {
  return {
    type: NOTIFICATION_CATEGORY_LIST_LOADING,
    payload
  };
}
export function notificationCategorySuccess(payload) {
  return {
    type:NOTIFICATION_CATEGORY_LIST_SUCCESS,
    payload
  };
}

export function notificationCategoryFailure(payload) {
  return {
    type: NOTIFICATION_CATEGORY_LIST_FAILURE,
    payload
  };
}
