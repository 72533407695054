import { Grid, IconButton } from "@mui/material";
import Search from '@iconify/icons-mdi/search';
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypography } from "../../BaseComponents/ETypography";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import ifsc from 'ifsc-finder';
import { EIconButton } from "../../BaseComponents/EButtons";
import { EIcon } from "../../BaseComponents/EIcon";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';

const AddFormBankDetail = (props) => {

    const { formikTalents, isOpen, TalentSetting} = props;
    const { errors, touched, getFieldProps, setFieldValue } = formikTalents;

    function CheckIFSCCode() {
        const ifscCode = getFieldProps('ifsc_code').value.toUpperCase();
        ifscCode && ifsc.get(ifscCode).then(function (res) {
            if (typeof (res) == 'object') {
                setFieldValue("bank_name", res.BANK)
                setFieldValue("branch_name", res.BRANCH)
                setFieldValue("ifsc_code", ifscCode)
            } else {
                setFieldValue("bank_name", "")
                setFieldValue("branch_name", "")
            }
        })
    }

    return (

        <ECard className={!getFieldProps('bank_detail_check').value ? 'card_design_2' : 'card_design_1 pb-8px'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography variant='h5' className="font-size-20px">
                    Bank Details
                </ETypography>
                {!isOpen &&
                <IconButton
                    onClick={() => setFieldValue('bank_detail_check', !getFieldProps('bank_detail_check').value)}>

                    <EIcon icon={getFieldProps('bank_detail_check').value ? minusBox : plusBox} />
                </IconButton>
                }
            </Grid>
            {getFieldProps('bank_detail_check').value && <>
                <EDivider />
                <Grid
                    container
                     spacing={2} 
                     padding={2}
                >
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'IFSC Code'+(TalentSetting?.field_ifsc_code?'*':'')} >
                            <ETextField
                                fullWidth
                                autoComplete="ifsc_code"
                                placeholder="Search IFSC Code"
                                {...getFieldProps('ifsc_code')}
                                error={Boolean(touched.ifsc_code && errors.ifsc_code)}
                                helperText={touched.ifsc_code && errors.ifsc_code}
                                InputProps={{
                                    endAdornment: (
                                        <EInputAdornment position="end">
                                            <EIconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => CheckIFSCCode()}

                                                size="large">
                                                <EIcon icon={Search} />
                                            </EIconButton>
                                        </EInputAdornment>
                                    ),
                                }}
                            />
                            {/* {IFSCCODEData && typeof (IFSCCODEData) != 'object' ? <FormHelperText style={{ color: 'red' }}>IFSC Code not found!</FormHelperText> : <></>} */}
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Bank Name'+(TalentSetting?.field_bank_name?'*':'')} >
                            <ETextField
                                fullWidth
                                // disabled={true}
                                placeholder="Bank Name"
                                autoComplete="bank_name"
                                {...getFieldProps('bank_name')}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.bank_name && errors.bank_name)}
                                helperText={touched.bank_name && errors.bank_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Branch Name'+(TalentSetting?.field_branch_name?'*':'')} >
                            <ETextField
                                fullWidth
                                multiline
                                autoComplete="branch_name"
                                placeholder="Branch Name"
                                {...getFieldProps('branch_name')}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.branch_name && errors.branch_name)}
                                helperText={touched.branch_name && errors.branch_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={`Cardholder's Name`+(TalentSetting?.field_account_holder_name?'*':'')} >
                            <ETextField
                                fullWidth
                                placeholder="Cardholder's Name"
                                autoComplete="bank_account_holder_name"
                                {...getFieldProps('bank_account_holder_name')}
                                error={Boolean(touched.bank_account_holder_name && errors.bank_account_holder_name)}
                                helperText={touched.bank_account_holder_name && errors.bank_account_holder_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={`Account Number`+(TalentSetting?.field_account_number?'*':'')} >
                            <ETextField
                                fullWidth
                                placeholder="Account Number"
                                {...getFieldProps('account_no')}
                                error={Boolean(touched.account_no && errors.account_no)}
                                helperText={touched.account_no && errors.account_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>
            </>}
        </ECard>

    )

};

export default AddFormBankDetail;