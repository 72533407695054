import { Grid, Stack, styled } from "@mui/material";
import { ETypography } from "../BaseComponents/ETypography";
import React from "react";
import { AuthEmailSent } from "../layouts/icons/auth-email-sent";
import { ELoadingButton } from "../BaseComponents/EButtons";
import { useNavigate } from "react-router-dom";

const AuthGrid = styled(Grid)(({ theme }) => (
    {
        textAlign: 'center',
    }));


const AuthResetPasswordSuccess = () => {
    const navigate = useNavigate()
    return (
        <Grid>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >

                <AuthGrid mb={2}>
                    <AuthEmailSent />
                </AuthGrid>

                <AuthGrid>
                    <ETypography variant="h5" className="font-size-20px">
                        Password changed!
                    </ETypography>
                    <ETypography className="font-size-16px mt-8px ">
                        Your password has been changed successfully.
                    </ETypography>
                </AuthGrid>

                <ELoadingButton
                    fullWidth
                    size="medium"
                    type="submit"
                    className="borderRadius-4px font-size-16px bold-700 mt-24px "
                    onClick={() => { navigate("/login") }}
                >
                    Login
                </ELoadingButton>
            </Stack>
        </Grid>
    );
};

export default AuthResetPasswordSuccess;