import { styled } from '@mui/material/styles';

// Changes by - Aanchal Sahu on 4/18/23
// INFO: Commented dark themed icon since this component is used in pages before login(AuthResetPasswordForm) where we are only entertaining the light theme
export const AuthKeyIcon = styled((theme) => {
 
  return (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
      <g mask="url(#mask0_1732_117099)">
        <path d="M45 75C43.5833 75 42.395 74.52 41.435 73.56C40.4783 72.6033 40 71.4167 40 70C40 68.5833 40.4783 67.395 41.435 66.435C42.395 65.4783 43.5833 65 45 65C46.4167 65 47.605 65.4783 48.565 66.435C49.5217 67.395 50 68.5833 50 70C50 71.4167 49.5217 72.6033 48.565 73.56C47.605 74.52 46.4167 75 45 75ZM45 93.5C38.5 93.5 32.9583 91.2083 28.375 86.625C23.7917 82.0417 21.5 76.5 21.5 70C21.5 63.5 23.7917 57.9583 28.375 53.375C32.9583 48.7917 38.5 46.5 45 46.5C50 46.5 54.5 47.895 58.5 50.685C62.5 53.4783 65.3333 57.0833 67 61.5H113.625L122.125 70L108.125 83.75L100 77.625L91.25 84L82.875 78.5H67C65.3333 82.8333 62.5 86.4167 58.5 89.25C54.5 92.0833 50 93.5 45 93.5ZM45 90C50 90 54.23 88.5 57.69 85.5C61.1467 82.5 63.3333 79 64.25 75H84L91.125 79.75L100.125 73.375L108 79.25L117.25 70L112.25 65H64.25C63.3333 61 61.1467 57.5 57.69 54.5C54.23 51.5 50 50 45 50C39.5 50 34.7917 51.9583 30.875 55.875C26.9583 59.7917 25 64.5 25 70C25 75.5 26.9583 80.2083 30.875 84.125C34.7917 88.0417 39.5 90 45 90Z" fill="#403F3F" />
        <circle cx="45" cy="70" r="6" fill="#D97904" />
      </g>
    </svg>
  );
})``;

// Dark themed icon
// return (
//   <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
//     <g mask="url(#mask0_1732_119968)">
//       <path d="M45 75C43.5833 75 42.395 74.52 41.435 73.56C40.4783 72.6033 40 71.4167 40 70C40 68.5833 40.4783 67.395 41.435 66.435C42.395 65.4783 43.5833 65 45 65C46.4167 65 47.605 65.4783 48.565 66.435C49.5217 67.395 50 68.5833 50 70C50 71.4167 49.5217 72.6033 48.565 73.56C47.605 74.52 46.4167 75 45 75ZM45 93.5C38.5 93.5 32.9583 91.2083 28.375 86.625C23.7917 82.0417 21.5 76.5 21.5 70C21.5 63.5 23.7917 57.9583 28.375 53.375C32.9583 48.7917 38.5 46.5 45 46.5C50 46.5 54.5 47.895 58.5 50.685C62.5 53.4783 65.3333 57.0833 67 61.5H113.625L122.125 70L108.125 83.75L100 77.625L91.25 84L82.875 78.5H67C65.3333 82.8333 62.5 86.4167 58.5 89.25C54.5 92.0833 50 93.5 45 93.5ZM45 90C50 90 54.23 88.5 57.69 85.5C61.1467 82.5 63.3333 79 64.25 75H84L91.125 79.75L100.125 73.375L108 79.25L117.25 70L112.25 65H64.25C63.3333 61 61.1467 57.5 57.69 54.5C54.23 51.5 50 50 45 50C39.5 50 34.7917 51.9583 30.875 55.875C26.9583 59.7917 25 64.5 25 70C25 75.5 26.9583 80.2083 30.875 84.125C34.7917 88.0417 39.5 90 45 90Z" fill="#F5F5F5" />
//       <circle cx="45" cy="70" r="6" fill="#D97904" />
//     </g>
//   </svg>
// );