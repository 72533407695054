import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, IconButton, useTheme } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { NIL, SUPPORT_STATUS } from '../../constants';
import { EIcon } from '../../BaseComponents/EIcon';
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import {  ELabel, ETypography, ETypographyCardHeading, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { ETextField } from '../../BaseComponents/ETextField';
import { EDivider, EDividerCard } from '../../BaseComponents/EDivider';
import { EBoxPage, ItemBox } from '../../BaseComponents/EBox';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ReplyTicket, TicketDetails } from '../../action/SupportAction';
import moment from 'moment';
import EChip from '../../BaseComponents/EChip';
import base64 from 'base-64'
import { EPaper } from '../../BaseComponents/EPaper';
import { ECard } from '../../BaseComponents/ECard';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { useFormik, Form, FormikProvider } from 'formik';
import { ReplyTicketValidation } from '../../utils/validation';
import { ELinkMUI } from '../../BaseComponents/ELink';
import plusBox from '@iconify/icons-material-symbols/add-box-outline'
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded'
import { capitalizeCapitalString } from '../../utils/formatText';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';

function SupportTicketDetail(props) {
    const { auth } = props;
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch();
    const theme = useTheme()

    const support = useSelector((state) => state.SupportReducer);

    const [openReplyBox, setOpenReplyBox] = useState(false);
    const [ticketData, setSupportData] = useState(null);
    const [tktStatus, setTktStatus] = useState(null);

    const formik = useFormik({
        initialValues: {
            message: "",
            attachment: "",
            ticket_id: ''
        },
        validationSchema: ReplyTicketValidation,
        onSubmit: (data, { resetForm }) => {
            data = { ...data, 'createdBy': auth?.authData?.id }
            dispatch(ReplyTicket(auth.authtoken, data))
            setOpenReplyBox(false)
            resetForm();
        },
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (params?.id) {
            dispatch(TicketDetails(auth.authtoken, base64.decode(params?.id)));
        }
    }, [params?.id])

    useEffect(() => {
        const ticketData = support?.supportData;
        const tktStatus = SUPPORT_STATUS[ticketData?.support_transaction_status];
        setSupportData(ticketData)
        setTktStatus(tktStatus)
        setFieldValue('ticket_id', ticketData?.id);
    }, [support.supportData])

    const PhotoChange = (e) => {
        if (e && e.target.files) {
            setFieldValue("attachment", e.target.files);
        }
    }

    const lastReply = ticketData?.SupportDetail?.length > 0 ? ticketData?.SupportDetail[ticketData?.SupportDetail?.length - 1] : null
    const lastReplyBy = lastReply ? lastReply?.messageClient?.fullname || lastReply?.messageSupport?.fullname : ''

    return (
        <Page title="Support" >
            <Grid container display="row" justifyContent="space-between" className='align-center'>
                <Grid item >
                    <EHeaderBreadcrumbs
                        heading={`Ticket - ${ticketData?.ticket_code}`}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Support', href: '/support/tickets' },
                            { name: 'Support Ticket', href: '/support/tickets' },
                            {
                                name: `Ticket - ${ticketData?.ticket_code}`,
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>{`Ticket Number - ${ticketData?.ticket_code}`}</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px'/>
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
                <EHidden width="mdDown">  <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid></EHidden>
            </Grid>

            <EBoxPage>
                <EPaper className='theme-color-border card_design_1 shadow-none' elevation={8}>
                    <Grid container spacing={2}  >
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ETypographyCardHeading variant='h5'>Ticket Information</ETypographyCardHeading>
                        </Grid>
                        <Grid xs={12} lg={12} item className='pt-8px'>
                            <EDividerCard />
                        </Grid>

                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                               {ticketData?.ticketOwner?.fullname?ticketData?.ticketOwner?.fullname:NIL} 
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Generated On'} isNotForm={true}>
                               {ticketData?.created_at? moment(ticketData?.created_at).format("DD/MM/YYYY hh:mm A"):NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Department'} isNotForm={true}>
                               {ticketData?.support_category?ticketData?.support_category:NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Updated On'} isNotForm={true}>
                               {ticketData?.SupportLastMessage?.created_at? moment(ticketData?.SupportLastMessage?.created_at).format("DD/MM/YYYY hh:mm A"):NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Status'} isNotForm={true}>
                               {tktStatus? capitalizeCapitalString(tktStatus):NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Updated By'} isNotForm={true}>
                               {lastReplyBy}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item >
                            <ELabelInputMainGrid label={'Priority'} isNotForm={true}>
                                {ticketData?.support_priority? ticketData?.support_priority:NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item >
                        <ELabel 
                            sx={{ 
                                color: theme.palette.text.greyColor4, 
                                fontSize: { lg: '14px !important', sm: '12px !important' },
                            }} 
                        >
                            Note: The ticket will be automatically closed after 48 hours of being inactive.
                        </ELabel>
                        </Grid>
                        
                    </Grid>
                </EPaper>

                {ticketData?.support_status == 'OPEN' &&

                    <ECard className={openReplyBox?'card_design_1':'card_design_2'} >
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            >
                            <ETypographyCardHeading variant='h5'>Reply</ETypographyCardHeading>
                            <IconButton  onClick={() => setOpenReplyBox(!openReplyBox)} className='p0'>
                                <EIcon icon={!openReplyBox?plusBox:minusBox} height={18} width={18} />
                            </IconButton>
                        </Grid>
                        {openReplyBox && <>
                            <FormikProvider value={formik} id="previewBlock">
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid xs={12} lg={12} item >
                                            <EDividerCard className='mt-8px' />
                                        </Grid>

                                        <Grid xs={12} lg={12} item>
                                            <ETextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                placeholder={"Message"}
                                                name="message"
                                                {...getFieldProps("message")}
                                                error={Boolean(touched.message && errors.message)}
                                                helperText={touched.message && errors.message}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={7} md={5}  lg={5}  xl={4} item className="input-file-selector">
                                            <ETextField
                                                fullwidth="true"
                                                type="file"
                                                fullWidth
                                                multiple
                                                accept='image/png, image/jpg, image/jpeg'
                                                onChange={(e) => { PhotoChange(e) }}
                                                error={Boolean(touched.attachment && errors.attachment)}
                                                helperText={'Allowed formats are *.jpeg, *.jpg & *.png'}
                                            />
                                        </Grid>
                                        <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                                            <ItemBox><EButtonOutlined variant="outlined" onClick={() => setOpenReplyBox(false)} >  Cancel </EButtonOutlined></ItemBox>
                                            <ItemBox><EButton variant="contained" type="submit" >  Submit </EButton></ItemBox>
                                        </Grid>

                                    </Grid>
                                </Form>
                            </FormikProvider>
                        </>}
                    </ECard>
                }

                {ticketData?.SupportDetail && Object.keys(ticketData?.SupportDetail).map((index, row) => {
                    const { is_staff, id, sid, created_at, support_description, messageClient, messageSupport, SupportDetailFiles } = ticketData?.SupportDetail[index];
                    let status = is_staff ? 'ENTERA' : 'Owner';
                    const idd = ticketData.ticket_code + "-" + id;
                    return (
                        <ECard className='card_design_1' key={idd} >

                            <Grid container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                                <ETypographyCardHeading variant='h5'>
                                    {is_staff ? messageSupport?.fullname : messageClient?.fullname} <EChip label={status} />
                                </ETypographyCardHeading>
                                <ETypography>{moment(created_at).format("DD/MM/YYYY hh:mm A")}</ETypography>
                            </Grid>
                            <Grid xs={12} lg={12} item mb={2}>
                                <EDividerCard className='mt-8px' />
                            </Grid>

                            <Grid xs={12} lg={12} item mb={2}>
                                <ETypography dangerouslySetInnerHTML={{ __html: support_description }}></ETypography>
                            </Grid>

                            {SupportDetailFiles && SupportDetailFiles.length > 0 &&

                                <Grid xs={12} lg={12} item mb={2} mt={2}>
                                    <ETypography> Attachment </ETypography>
                                    {SupportDetailFiles && SupportDetailFiles.map((row, index) => {
                                        const { support_detail_image, id } = row;
                                        const keyChiledTwo = 'detail' + id;
                                        const title = `Attachment ${index+1}`
                                        return (
                                            <ETypography key={keyChiledTwo} >
                                                <ELinkMUI 
                                                    href={support_detail_image} 
                                                    target="_blank" 
                                                    variant="body2" 
                                                    sx={{ color: theme.palette.text.staticBlue3 }}
                                                >
                                                    {title}
                                                </ELinkMUI>
                                            </ETypography>
                                        )
                                    })}

                                </Grid>
                            }



                        </ECard>
                    )
                })}

            </EBoxPage>

        </Page>
    );
}
export default SupportTicketDetail