import {
    LEAVE_LIST_FAILURE, LEAVE_LIST_SUCCESS, LEAVE_LIST_LOADING, LEAVE_FAILURE, LEAVE_LOADING, LEAVE_SUCCESS
} from '../constants';

const INITIAL_STATE = {
    leaveListData: {},
    leaveListSuccess: false,
    leaveListLoading: false,
    leaveListMessage: null,
    leaveData: {},
    leaveSuccess: false,
    leaveLoading: false,
    leaveMessage: null,
};

const LeaveComponentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LEAVE_LOADING:
            return {
                leaveLoading: true,
                leaveData: {}
            };

        case LEAVE_SUCCESS:
            return {
                leaveLoading: false,
                leaveSuccess: true,
                leaveData: action.payload.data,
                leaveMessage: action.payload.message,
            };

        case LEAVE_FAILURE:
            return {
                leaveLoading: false,
                leaveSuccess: false,
                leaveMessage: action.payload.message,
            };

        // FOR LEAVE LIST 

        case LEAVE_LIST_LOADING:
            return {
                leaveListLoading: true,
                leaveListData: {}
            };

        case LEAVE_LIST_SUCCESS:
            return {
                leaveListLoading: false,
                leaveListSuccess: true,
                leaveListData: action.payload.data,
                leaveListMessage: action.payload.message,
            };

        case LEAVE_LIST_FAILURE:
            return {
                leaveListLoading: false,
                leaveListSuccess: false,
                leaveListMessage: action.payload.message,
                leaveListData: {}
            };

        default:
            return state;
    }
};

export default LeaveComponentReducer;
