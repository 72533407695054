import { Box, Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import {  ETypography } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EIcon } from "../../BaseComponents/EIcon";
import { useSelector } from "react-redux";
import { SUPER_ADMIN } from "../../constants";

const AddFormUserLogin = (props) => {

    const { formikTalents, employeeList, auth } = props;
    const { errors, touched, getFieldProps, setFieldValue } = formikTalents;

    const ReportingManagerList = employeeList && employeeList?.length>0 &&  employeeList.filter(item => (item.is_active == 1))
    const PRIMARY_SUPER_ADMIN = employeeList?.filter(item => (item.is_primary))[0]

    const { customRoleData = [] } = useSelector((state) => state.CustomUserRole)

    return (
    <ECard p={20} className={!getFieldProps('user_check').value ? 'card_design_2' : 'card_design_1 pb-8px height-100per-16px'}>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <ETypography variant='h5' className="font-size-20px"> User Login </ETypography>
            <IconButton
                onClick={() => setFieldValue('user_check', !getFieldProps('user_check').value)}>
                <EIcon icon={getFieldProps('user_check').value ? minusBox : plusBox} />
            </IconButton>
        </Grid>
        {getFieldProps('user_check').value && <>
            <EDivider />
            <Grid container spacing={2} py={2} > {/* Responsive padding for card*/} 
                <Grid item  xl={7} lg={12} md={12} sm={6} xs={12}>
                    <ELabelInputMainGrid label={'Reporting Manager'}>
                        <EAutocomplete
                            name="reporting_manager"
                            fullWidth
                            options={ReportingManagerList && ReportingManagerList?.length > 0 ? ReportingManagerList : []}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            {...getFieldProps('reporting_manager')}
                            onChange={(e, value) => {
                                setFieldValue("reporting_manager", value ? value : null)
                            }}
                            disabled={formikTalents?.values?.role_object?.label?.replace(/\s/g, "").toUpperCase() == SUPER_ADMIN && true}
                            renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                <Box component="li" {...props} key={option.id}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={params => (
                                <ETextField
                                    {...params}
                                    name='reporting_manager'
                                    placeholder="Select Reporting Manager"
                                    error={Boolean(touched.reporting_manager && errors.reporting_manager)}
                                    helperText={touched.reporting_manager && errors.reporting_manager}
                                />
                            )}
                        />
                    </ELabelInputMainGrid>
                </Grid>
                <Grid item xl={5} className='display-up-xl'></Grid>

                <Grid item  xl={7} lg={12} md={12} sm={6} xs={12}>
                    <ELabelInputMainGrid label={'Role'}>
                        <EAutocomplete
                            name="role_object"
                            fullWidth
                            // disableClearable
                            options={customRoleData}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, value) => {
                                setFieldValue("role_object", value ? value : null)
                                if(value.label.replace(/\s/g, "").toUpperCase() == SUPER_ADMIN){ // if user selects SUPER_ADMIN, reporting manager will be PRIAMRY_SUPER_ADMIN
                                    setFieldValue("reporting_manager", PRIMARY_SUPER_ADMIN)
                                }
                              
                            }}
                            renderInput={params => (
                                <ETextField
                                    {...params}
                                    name='role_object'
                                    placeholder="Select Role"
                                    error={Boolean(touched.role_object && errors.role_object)}
                                    helperText={touched.role_object && errors.role_object}
                                />
                            )}
                        />
                    </ELabelInputMainGrid>
                </Grid>

                <Grid  item  xl={12} lg={12} md={12} sm={12} xs={12}>
                    <ETypography className="greyColor4-color font-size-14px mt-16px">
                    Note: After the user credentials are sent, the user's status will be reflected “disable” until they log in. Once they login and onboard the Enetra, their status will be updated to "Enable."   
                    </ETypography>

                </Grid>

            </Grid>
        </>}
    </ECard>
    )

};

export default AddFormUserLogin;
