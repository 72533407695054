import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardNavbar } from './dashboard-navbar'
import DashboardSidebar from './dashboard-sidebar';
import { DRAWER_WIDTH } from '../constants';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 76,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: DRAWER_WIDTH
  }
}));

function DashboardLayout(props) {
  const { isSettingLayout } = props;
  const auth = useSelector(state => state.authReducer);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  // const [currentTitle, setCurrentTitle] = useState('');//this is for storing current route

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Outlet />
        </Box>
      </DashboardLayoutRoot>

      <DashboardNavbar
        onSidebarOpen={() =>
          setSidebarOpen(true)}
        auth={auth}
      />
      <DashboardSidebar
        onCloseSidebar={() => setSidebarOpen(false)}
        isOpenSidebar={isSidebarOpen}
        isSettingLayout={isSettingLayout ? true : false}
        // setCurrentTitle={setCurrentTitle}
        // currentTitle={currentTitle}
        auth={auth}
      />

    </>
  );
};

export default DashboardLayout;