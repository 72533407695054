import { Container, Grid, Paper, styled } from "@mui/material";
import { EPaper } from "./EPaper";

export const EContainer = styled(Container)(({ theme }) => ({

}));

export const SalarySlipContainer = styled(EPaper)(({ theme }) => ({
    background: theme.palette.primary.contrastText,
    borderRadius: '3%',
    overflow: 'hidden'
})); 


// ____________used in Auth Screens________
export const AuthRootStyle = styled(Container)(({ theme }) => ({
  paddingTop: '28px',
}));

// ____________used in Auth Screens________
export const AuthContentStyle = styled(Paper)(({ theme }) => ({
  width: '430px',
  padding: '30px 40px',//'30px 40px 40px 40px'
  boxShadow: 'none !important',
}));

// ____________used in Auth Screens________
export const AuthContentInner = styled(Grid)(({ theme }) => ({
  padding: '30px 40px',//'30px 40px 40px 40px'
}));