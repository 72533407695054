import { styled } from '@mui/material/styles';

// Changes by - Aanchal Sahu on 4/18/23
// INFO: Commented dark themed icon since this component is used in pages before login(AuthForgotEmailForm) where we are only entertaining the light theme
export const AuthLockIcon = styled(() => {
        return (
            <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
                <g mask="url(#mask0_1732_117084)">
                    <path d="M44.6183 108.183C42.5846 108.183 40.8567 107.47 39.4347 106.045C38.0095 104.623 37.2969 102.895 37.2969 100.862V59.8616C37.2969 57.8279 38.0095 56.0984 39.4347 54.6732C40.8567 53.2512 42.5846 52.5402 44.6183 52.5402H51.9397V42.7783C51.9397 37.7346 53.6887 33.4638 57.1868 29.9658C60.6848 26.4678 64.9556 24.7188 69.9993 24.7188C75.0429 24.7188 79.3137 26.4678 82.8118 29.9658C86.3098 33.4638 88.0588 37.7346 88.0588 42.7783V52.5402H95.3802C97.4139 52.5402 99.1418 53.2512 100.564 54.6732C101.989 56.0984 102.702 57.8279 102.702 59.8616V100.862C102.702 102.895 101.989 104.623 100.564 106.045C99.1418 107.47 97.4139 108.183 95.3802 108.183H44.6183ZM44.6183 104.766H95.3802C96.5191 104.766 97.4546 104.4 98.1868 103.668C98.9189 102.936 99.285 102 99.285 100.862V59.8616C99.285 58.7227 98.9189 57.7872 98.1868 57.0551C97.4546 56.3229 96.5191 55.9568 95.3802 55.9568H44.6183C43.4794 55.9568 42.5439 56.3229 41.8118 57.0551C41.0796 57.7872 40.7135 58.7227 40.7135 59.8616V100.862C40.7135 102 41.0796 102.936 41.8118 103.668C42.5439 104.4 43.4794 104.766 44.6183 104.766ZM69.9993 86.9509C71.8703 86.9509 73.4371 86.3213 74.6996 85.062C75.9589 83.7994 76.5885 82.2326 76.5885 80.3616C76.5885 78.4906 75.9589 76.9238 74.6996 75.6613C73.4371 74.402 71.8703 73.7723 69.9993 73.7723C68.1282 73.7723 66.5631 74.402 65.3038 75.6613C64.0412 76.9238 63.41 78.4906 63.41 80.3616C63.41 82.2326 64.0412 83.7994 65.3038 85.062C66.5631 86.3213 68.1282 86.9509 69.9993 86.9509ZM55.3564 52.5402H84.6421V42.7783C84.6421 38.7108 83.2185 35.2535 80.3713 32.4063C77.5241 29.559 74.0667 28.1354 69.9993 28.1354C65.9318 28.1354 62.4745 29.559 59.6272 32.4063C56.78 35.2535 55.3564 38.7108 55.3564 42.7783V52.5402Z" fill="#403F3F" />
                    <circle cx="70" cy="80" r="7" fill="#D97904" />
                </g>
            </svg>
        );
})``;

// Dark themed ICon
// return (
//     <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <circle cx="70" cy="70" r="68.5" stroke="#D97904" strokeWidth="3" />
//         <g mask="url(#mask0_1732_119991)">
//             <path d="M44.6183 108.183C42.5846 108.183 40.8567 107.47 39.4347 106.045C38.0095 104.623 37.2969 102.895 37.2969 100.862V59.8616C37.2969 57.8279 38.0095 56.0984 39.4347 54.6732C40.8567 53.2512 42.5846 52.5402 44.6183 52.5402H51.9397V42.7783C51.9397 37.7346 53.6887 33.4638 57.1868 29.9658C60.6848 26.4678 64.9556 24.7188 69.9993 24.7188C75.0429 24.7188 79.3137 26.4678 82.8118 29.9658C86.3098 33.4638 88.0588 37.7346 88.0588 42.7783V52.5402H95.3802C97.4139 52.5402 99.1418 53.2512 100.564 54.6732C101.989 56.0984 102.702 57.8279 102.702 59.8616V100.862C102.702 102.895 101.989 104.623 100.564 106.045C99.1418 107.47 97.4139 108.183 95.3802 108.183H44.6183ZM44.6183 104.766H95.3802C96.5191 104.766 97.4546 104.4 98.1868 103.668C98.9189 102.936 99.285 102 99.285 100.862V59.8616C99.285 58.7227 98.9189 57.7872 98.1868 57.0551C97.4546 56.3229 96.5191 55.9568 95.3802 55.9568H44.6183C43.4794 55.9568 42.5439 56.3229 41.8118 57.0551C41.0796 57.7872 40.7135 58.7227 40.7135 59.8616V100.862C40.7135 102 41.0796 102.936 41.8118 103.668C42.5439 104.4 43.4794 104.766 44.6183 104.766ZM69.9993 86.9509C71.8703 86.9509 73.4371 86.3213 74.6996 85.062C75.9589 83.7994 76.5885 82.2326 76.5885 80.3616C76.5885 78.4906 75.9589 76.9238 74.6996 75.6613C73.4371 74.402 71.8703 73.7723 69.9993 73.7723C68.1282 73.7723 66.5631 74.402 65.3038 75.6613C64.0412 76.9238 63.41 78.4906 63.41 80.3616C63.41 82.2326 64.0412 83.7994 65.3038 85.062C66.5631 86.3213 68.1282 86.9509 69.9993 86.9509ZM55.3564 52.5402H84.6421V42.7783C84.6421 38.7108 83.2185 35.2535 80.3713 32.4063C77.5241 29.559 74.0667 28.1354 69.9993 28.1354C65.9318 28.1354 62.4745 29.559 59.6272 32.4063C56.78 35.2535 55.3564 38.7108 55.3564 42.7783V52.5402Z" fill="#F5F5F5" />
//             <circle cx="70" cy="80" r="7" fill="#D97904" />
//         </g>
//     </svg>
// );