import React, { useEffect, useState } from 'react';
import { Grid, Avatar, IconButton} from '@mui/material';
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { useSelector, useDispatch } from 'react-redux';
import { GetCompanySetting } from '../../action/CompanySettingAction';
import Page from '../../BaseComponents/EPage';
import { ELabel, ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { ECard } from '../../BaseComponents/ECard';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import { EIcon } from '../../BaseComponents/EIcon';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import CompanyProfileForm from '../../PageComponents/company/CompanyProfileForm';
import { EDivider } from '../../BaseComponents/EDivider';
import { StateList } from '../../action/StateCityAction';
import { COMPANY_TYPE, PERMISSIONS } from '../../constants';
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { useCheckPermission } from '../../hooks';



/** 
 * [2022-10-07] 
 * Changes By :- Gaurav Singh
 * Description :- Changed  'Company Info' to 'Basic Details' and 'Contact Person Details ' to 'Contact Details'  
 **/
function CompanyProfile(props) {

    const { setting } = useCompanySettingProvider();
    const { auth  } = props;
  
    const dispatch = useDispatch();
  
    const company = useSelector((state) => state.CompanyReducer?.companyData);
    const primaryAdmin = setting.accountSettingData?.companySetting?.company_data?.primaryAdmin;
    const [IsFormEdit, SetIsFormEdit] = useState(false);

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditCompanyProfile])

    useEffect(() => {
        if (auth.authData?.id) {
            // dispatch(GetCompanySetting(auth.authtoken, auth.authData?.company_id));
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
        }
    }, [auth]);

    // console.log(setting); 

    return (
        <>
            <Page title="Company Profile" >

                <Grid container display="row" justifyContent="space-between" className='align-center'>
                    <Grid item >
                        <EHeaderBreadcrumbs
                            heading='Company Profile'
                            links={[
                                { name: 'Dashboard', href: '/' },
                                { name: 'Setting', href: '' },
                                {
                                    name: 'Company Profile',
                                }
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                    <Grid item>
                        <ETypographyPageHeading variant='h5'> Company Profile</ETypographyPageHeading>
                    </Grid>

                    <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid>
                </Grid>

                <EBoxPage>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        {IsFormEdit && <Grid item xs={12} sm={12} md={12} my={1}>
                            <CompanyProfileForm 
                                settingData={company}
                                auth={auth}
                                SetIsFormEdit={SetIsFormEdit} 
                                    />
                        </Grid>
                        }
                        {!IsFormEdit &&
                            <Grid item xs={12} sm={12} md={12} my={1}>
                                <ECard p={20}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"

                                    >
                                        <ETypography variant='h5'>
                                            Basic Details
                                        </ETypography>
                                        {
                                            allowEdit &&
                                            <IconButton onClick={() => SetIsFormEdit(true)} >
                                                <EIcon icon={EditIcon} className='p0' />
                                            </IconButton>
                                        }
                                    </Grid>

                                    <EDivider className='margin-top-05' />

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        py={3}


                                    >
                                        <Avatar
                                            alt="Profile Pic"
                                            src={company?.company_logo ? company?.company_logo : ''}
                                            sx={{
                                                width: 140,
                                                height: 140,
                                                mx: 'auto',
                                                borderColor: 'text.primary'
                                            }}
                                        />

                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}

                                    >
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Company Name'} isfullgrid={true} labelClassname="align-center bold-600">
                                                <ETypography variant={'h4'} className="text-capitalize">
                                                    {company?.company_name ? company?.company_name : '-'}
                                                </ETypography>
                                            </ELabelInputMainGrid>
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Company Type'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.company_type ? COMPANY_TYPE[company?.company_type] : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>

                                        {/* <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'About Us'} isfullgrid={true} labelClassname="bold-600">
                                                {company?.company_description ? company?.company_description : '-'}
                                            </ELabelInputMainGrid>
                                        </Grid> */}
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Email ID'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.company_email ? company?.company_email : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Contact Number'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.company_phone ? company?.company_phone : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'GSTIN Number'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.company_gst ? company?.company_gst : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'PAN Number'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.company_pan ? company?.company_pan : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Permanent Address'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.address ? company?.address : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'State'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.permanentState?.label ? company?.permanentState?.label : ''}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'City'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.permanentCity?.label ? company?.permanentCity?.label : ''}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'PIN Code'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.pin_code ? ' ' + company?.pin_code : ''} </ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>

                                        {/* {formik.values.cr_address_same &&
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checkedIcon={<EIcon icon={checkBox} color={theme.palette.text.greyDisabledColor} />}
                                                                {...getFieldProps('cr_address_same')}
                                                                disabled
                                                                checked={true}
                                                                className='py0'
                                                            />
                                                        }
                                                        label={
                                                            <ETypography variant="body2" className="light-text-css">
                                                                Same as Permanent Address
                                                            </ETypography>
                                                        }
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        } */}
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'Correspondence Address'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.cr_address ? company?.cr_address : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'State'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.crState?.label ? company?.crState?.label : ''}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'City'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.crCity?.label ? company?.crCity?.label : ''}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <ELabelInputMainGrid label={'PIN Code'} isfullgrid={true} isNotForm={true}>
                                                <ELabel>{company?.cr_pin_code ? ' ' + company?.cr_pin_code : ''}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    </Grid>
                                </ECard>
                            </Grid>
                        }

                        {/* Business owner */}
                        <Grid item xs={12} sm={12} md={12} my={1}>
                                <ECard p={20}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <ETypography variant='h5'>
                                            Primary Contact
                                        </ETypography>

                                    </Grid>
                                    <EDivider className='margin-top-05' />
                                    <Grid
                                        container
                                        py={3}

                                    >
                                        <Grid item md={6} sm={6} xs={12}>
                                            <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                                                {primaryAdmin?.fullname ? primaryAdmin?.fullname : '-'}
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <ELabelInputMainGrid label={'Contact Number'} isNotForm={true}>
                                                <ELabel>{primaryAdmin?.mobile ? primaryAdmin?.mobile : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <ELabelInputMainGrid label={'Email ID'} isNotForm={true}>
                                                <ELabel>{primaryAdmin?.email ? primaryAdmin?.email : '-'}</ELabel>
                                            </ELabelInputMainGrid>
                                        </Grid>

                                    </Grid>
                                </ECard>
                            </Grid>
                    </Grid>
                </EBoxPage>
            </Page>
        </>
    );
}
export default CompanyProfile;
