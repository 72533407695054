import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EAccordion,
  EAccordionDetails,
  EAccordionSummary,
} from "../BaseComponents/EAccordion";
import EHeaderBreadcrumbs from "../BaseComponents/EBreadcrum";
import {
  EButtonOutlined,
  EButtonOutlinedIcon,
} from "../BaseComponents/EButtons";
import {
  EIcon,
  EIconInputAdornment,
  EIconAccordion,
} from "../BaseComponents/EIcon";
import Page from "../BaseComponents/EPage";
import {
  EInputAdornment,
  ETextFieldSearch,
} from "../BaseComponents/ETextField";
import { ETooltip } from "../BaseComponents/ETooltip";
import {
  ETypography,
  ETypographyPageHeading,
} from "../BaseComponents/ETypography";

import { FAQSdata } from "../FAQSdata";
import SearchNotFound from "../PageComponents/SearchNotFound";
import { EDivider } from "../BaseComponents/EDivider";
import search from "@iconify/icons-material-symbols/search";
import minusBox from "@iconify/icons-material-symbols/indeterminate-check-box-rounded";
import plusBox from "@iconify/icons-material-symbols/add-box-rounded";
import { EBoxPage } from "../BaseComponents/EBox";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import EHidden from "../BaseComponents/EHidden";

/**
 * [2022-11-21]
 * Admin: Aanchal Sahu
 * Description:- Made this FAQ page and made it dynamic
 **/
function FAQs() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState("panel1");
  const [List, setList] = useState(FAQSdata?.rows);
  const [searchedValue, setSearchedValue] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "panel1");
  };

  /**
   * [2022-01-06]
   * Admin: Purva Sharma
   * Description:- Made highlight on search
   **/

  // Reference for highlight on search : https://dev.to/_martinwheeler_/create-a-react-search-bar-that-highlights-your-results-4hdh
  // codesandbox:https://codesandbox.io/s/react-highlighter-with-full-text-search-case-sensitive-jc6zu7?file=/src/App.js

  // Function to convert text to mark up to pass to dangerouslySetInnerHtml
  const createMarkup = (html) => {
    return { __html: html };
  };

  useEffect(() => {
    highlightKeywords();
    //react-hooks/exhaustive-deps
  }, [searchedValue]);

  // traverseElement: looping over the text and replacing the keywords using textReplacerFunc()
  function traverseElement(tag, el, regex, textReplacerFunc) {
    if (!/^(script|style)$/.test(el.tagName)) {
      var child = el.lastChild;

      while (child) {
        if (child.nodeType == 1) {
          traverseElement(tag, child, regex, textReplacerFunc);
        } else if (child.nodeType == 3) {
          textReplacerFunc(tag, child, regex);
        }
        child = child.previousSibling;
      }
    }
  }

  // textReplacerFunc: replacing keywords with mark tag
  function textReplacerFunc(tag, textNode, regex, text) {
    textNode.data = textNode.data.replace(
      regex,
      "<Mark style='color:#D97904 !important;background:none; font-weight:600'>$1</Mark>"
    );
  }

  // replaceWords : replacing words inside html tags
  function replaceWords(html, words) {
    var container = document.createElement("div");
    container.innerHTML = html;
    for (var i = 0, len = words.length; i < len; ++i) {
      traverseElement(
        words[i],
        container,
        new RegExp("(" + words[i] + ")", "i"),
        textReplacerFunc
      );
    }
    return t(container.innerHTML);
  }

  // t: replacing tags i.e <, >
  function t(t) {
    return t.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  }

  //   multiSearch : function does full text search
  function multiSearchOr(text, searchWords) {
    var searchExp = new RegExp(searchWords.join("|"), "gi");
    return searchExp.test(text) ? true : false;
  }

  // highlightKeywords:  Main Function
  function highlightKeywords() {
    let keyword = searchedValue.split(" ");
    keyword = keyword.filter(function (str) {
      return /\S/.test(str);
    });
    let newArr = List.filter(
      (item) =>
        multiSearchOr(item.questions, keyword) ||
        multiSearchOr(item.answers, keyword)
    ).map((item) => {
      let newQuestions = replaceWords(item.questions, keyword);
      let newAnswers = replaceWords(item.answers, keyword);

      return {
        ...item,
        questions: newQuestions,
        answers: newAnswers,
      };
    });
    setSearchedData(newArr);
  }

  // Function to search and highlight the words
  const handleInput = (e) => {
    let str = e.target.value;
    str !== " " ? setSearchedValue(str) : setList(List);
  };

  // Removing duplicates
  const uniqueIds = [];
  const filteredData = searchedData.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id);

    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }

    return false;
  });

  return (
    <Page title="FAQ">
      <Grid container display="row" justifyContent="space-between">
        <EHeaderBreadcrumbs
          heading="FAQ"
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Support", href: "/company-profile/faqs" },
            {
              name: "FAQ",
            },
          ]}
        />
        <EHidden width="mdDown">
          <Grid item>
            <ETooltip title={"Back"} arrow>
              <EButtonOutlined variant="outlined" onClick={() => navigate(-1)}>
                Back{" "}
              </EButtonOutlined>
            </ETooltip>
          </Grid>
        </EHidden>
      </Grid>

      <Grid
        container
        // display="row"
        direction="row"
        justifyContent="space-between"
        className="pb-2rem margin-top-20px"
      >
        <Grid item display={"flex"} alignItems={"flex-end"}>
          <ETypographyPageHeading variant="h5">FAQ</ETypographyPageHeading>
          <ETypography element="span" variant="body2" className="ml-4px">
            {" "}
            (Frequently Asked Questions)
          </ETypography>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              <EIcon icon={ForwardArrow} className="height-width-15px" />
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
        <EHidden width="mdDown">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <ETextFieldSearch
              fullWidth
              color="primary"
              size="small"
              placeholder="Search"
              value={searchedValue}
              onChange={(e) => {
                handleInput(e);
              }}
              InputProps={{
                startAdornment: (
                  <ETooltip arrow title="Search">
                    <EInputAdornment position="start">
                      <EIconInputAdornment icon={search}></EIconInputAdornment>
                    </EInputAdornment>
                  </ETooltip>
                ),
                endAdornment: (
                  <ETooltip arrow title="Cancel">
                    <EInputAdornment
                      position="end"
                      sx={{ margin: "0px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        setSearchedValue("");
                        setSearchedData([]);
                      }}
                    >
                      {searchedValue?.length !== 0 && (
                        <EIcon icon={CancelIcon} />
                      )}
                    </EInputAdornment>
                  </ETooltip>
                ),
              }}
            />
          </Grid>
          <Grid xs={12} lg={12} item>
            <EDivider className="margin-top-05" />
          </Grid>
        </EHidden>

        {/* Search for small screen i.e hidden for screen above 600 */}
        <EHidden width="mdUp">
          <Grid item sm={6} className="mt-16px">
            <ETextFieldSearch
              fullWidth
              color="primary"
              size="small"
              placeholder="Search"
              value={searchedValue}
              onChange={(e) => {
                handleInput(e);
              }}
              InputProps={{
                startAdornment: (
                  <ETooltip arrow title="Search">
                    <EInputAdornment position="start">
                      <EIconInputAdornment icon={search}></EIconInputAdornment>
                    </EInputAdornment>
                  </ETooltip>
                ),
                endAdornment: (
                  <ETooltip arrow title="Cancel">
                    <EInputAdornment
                      position="end"
                      sx={{ margin: "0px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        setSearchedValue("");
                        setSearchedData([]);
                      }}
                    >
                      {searchedValue?.length !== 0 && (
                        <EIcon icon={CancelIcon} />
                      )}
                    </EInputAdornment>
                  </ETooltip>
                ),
              }}
            />
          </Grid>
        </EHidden>
      </Grid>
      <EBoxPage>
        <Grid item sm={12} md={12} lg={12}>
          {searchedValue.length == 0 ? (
            List && List?.length > 0 ? (
              List?.map((rows, index) => {
                const { id, questions, answers } = rows;

                return (
                  <EAccordion
                    disableGutters
                    square
                    key={index + id}
                    expanded={expanded === "panel" + id}
                    onChange={handleChange("panel" + id)}
                  >
                    <EAccordionSummary
                      aria-controls={"panel" + id + "d-content"}
                      id={"panel" + id + "d-header"}
                      className="py0"
                    >
                      <Grid
                        container
                        justifyContent={"space-between"}
                        className="align-center"
                      >
                        <Grid item xs={11}>
                          <ETypography variant="h6" className="bold">
                            {questions}
                          </ETypography>
                        </Grid>
                        <Grid item xs={1} align="right">
                          {expanded == "panel" + id ? (
                            <EIconAccordion
                              icon={minusBox}
                            /> //width={18} height={18}
                          ) : (
                            <EIconAccordion
                              icon={plusBox}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </EAccordionSummary>
                    <EAccordionDetails>
                      <ETypography
                        component={"span"}
                        variant="body1"
                        className="font-size-14p5"
                        dangerouslySetInnerHTML={{ __html: answers }}
                      ></ETypography>
                    </EAccordionDetails>
                  </EAccordion>
                );
              })
            ) : (
              ""
            )
          ) : searchedValue.length !== 0 ? (
            filteredData.map(({ questions, answers, id, index }) => {
              return (
                <EAccordion
                  disableGutters
                  square
                  key={index + id}
                  expanded={expanded === "panel" + id}
                  onChange={handleChange("panel" + id)}
                >
                  <EAccordionSummary
                    aria-controls={"panel" + id + "d-content"}
                    id={"panel" + id + "d-header"}
                    className="py0"
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      className="align-center"
                    >
                      <Grid item xs={11}>
                        <ETypography
                          variant="h6"
                          className="bold"
                          dangerouslySetInnerHTML={createMarkup(questions)}
                        ></ETypography>
                      </Grid>
                      <Grid item xs={1} align="right">
                        {expanded == "panel" + id ? (
                          <EIconAccordion
                            icon={minusBox}
                          /> //width={18} height={18}
                        ) : (
                          <EIconAccordion
                            icon={plusBox}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </EAccordionSummary>
                  <EAccordionDetails>
                    <ETypography
                      component={"span"}
                      variant="body1"
                      className="font-size-14p5"
                      dangerouslySetInnerHTML={createMarkup(answers)}
                    ></ETypography>
                  </EAccordionDetails>
                </EAccordion>
              );
            })
          ) : (
            <SearchNotFound />
          )}
          {searchedValue.length !== 0 && filteredData.length === 0 && (
            <SearchNotFound />
          )}
        </Grid>
      </EBoxPage>
    </Page>
  );
}
export default FAQs;
