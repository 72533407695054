import React from "react";
import { Container } from '@mui/material';

import AuthPage from "../../BaseComponents/EAuthPage";
import { AuthContentInner, AuthContentStyle, AuthRootStyle } from "../../BaseComponents/EContainer";
import { AuthNavbar } from "../../layouts/auth-navbar";
import { ETypography } from "../../BaseComponents/ETypography";
import { ELinkMUI } from "../../BaseComponents/ELink";
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "../../constants";
import { AuthUpdateCredentialsForm } from "../../PageComponents/Auth/UpdateCredentials";


const UpdateCredentials = () => {

  return (
    <AuthPage title="Reset Password">
      <AuthNavbar />
      <AuthRootStyle title="Change Password">
        <Container maxWidth="sm" className="text-align-webkit-center">
          <AuthContentStyle className="p0 grayColor2-color-border">
            <AuthContentInner>
              <AuthUpdateCredentialsForm />
            </AuthContentInner>
          </AuthContentStyle>
          <AuthContentStyle className="p0 bg-transparent">
            <ETypography className="font-size-12px bold-400 text-align pt-18px px-16px">
              This site is protected by reCAPTCHA and the Google{" "}
              <ELinkMUI className='link-color' href={PRIVACY_POLICY_LINK} target="_blank">Privacy Policy </ELinkMUI> and{" "}
              <ELinkMUI className='link-color' href={TERMS_OF_SERVICE_LINK} target="_blank">Terms of Service </ELinkMUI> apply.
            </ETypography>
          </AuthContentStyle>
        </Container>
      </AuthRootStyle>
    </AuthPage>
  );
};

export default UpdateCredentials;
