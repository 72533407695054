import { Grid, Stack, styled } from "@mui/material";
import { ETypography } from "../BaseComponents/ETypography";
import { useNavigate } from 'react-router-dom';
import React from "react";
import { ForgetPassAction } from "../action/AuthAction";
import { useDispatch } from "react-redux";
import { ELink } from "../BaseComponents/ELink";

const AuthGrid = styled(Grid)(({ theme }) => (
    {
        textAlign: 'center',
    }));


const AuthForgotEmailSent = (props) => {
    const { email } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resendEmail = () => {
        dispatch(ForgetPassAction({ email }, navigate))
    }

    return (
        <Grid mt={15} ml={5} mr={5}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <AuthGrid>
                    <ETypography variant="h5" mb={2.5} >
                        Reset password email sent.
                    </ETypography>
                    <ETypography>
                        An email has been sent to your registered email ID. Please check and follow the instructions.
                    </ETypography>
                </AuthGrid>
            </Stack>
            <AuthGrid>
                <ETypography mt={14} className="font-weight-500-with-imp">
                    Didn’t receive an Email?
                    <ELink onClick={() => resendEmail()} className="link-color">
                        {' '}Resend
                    </ELink>
                </ETypography>
            </AuthGrid>
        </Grid>
    );
};

export default AuthForgotEmailSent;