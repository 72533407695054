import {
    DESIGNATION_LIST_FAILURE, DESIGNATION_LIST_SUCCESS, DESIGNATION_LIST_LOADING, DESIGNATION_FAILURE, DESIGNATION_LOADING, DESIGNATION_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    designationListData: {},
    designationListSuccess: false,
    designationListLoading: false,
    designationListMessage: null,
    designationData: {},
    designationSuccess: false,
    designationLoading: false,
    designationMessage: null,

};

const DesignationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case DESIGNATION_LOADING:
            return {
                ...state,
                designationLoading: true,
                designationData: {}
            };

        case DESIGNATION_SUCCESS:
            return {
                ...state,
                designationLoading: false,
                designationSuccess: true,
                designationData: action.payload.data,
                designationMessage: action.payload.message,
            };

        case DESIGNATION_FAILURE:
            return {
                ...state,
                designationLoading: false,
                designationSuccess: false,
                designationMessage: action.payload.message,
            };

        case DESIGNATION_LIST_LOADING:
            return {
                ...state,
                designationListLoading: true,
                designationListData: {}
            };

        case DESIGNATION_LIST_SUCCESS:
            return {
                ...state,
                designationListLoading: false,
                designationListSuccess: true,
                designationListData: action.payload.data,
                designationListMessage: action.payload.message,
            };

        case DESIGNATION_LIST_FAILURE:
            return {
                ...state,
                designationListLoading: false,
                designationListSuccess: false,
                designationListMessage: action.payload.message,
                designationListData: {}
            };

        default:
            return state;
    }
};

export default DesignationReducer;
