
//  [2022-11-28]
//  Changes by:- Aanchal Sahu
//  Description:- this function cam make capital words to capitalise; AANCHAL => Aanchal
  export function capitalizeCapitalString(string) {
    let x = string?.toLowerCase();
    x=x?.charAt(0)?.toUpperCase() + x?.slice(1);
    return  x;
  }

  export function sentenceCaseString(string) {
    return string.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

//  [2023-03-15]
//  Created by:- Aanchal Sahu
//  Description:- this function cam make a the first letter of any sentence capitalize to capitalise;EX: penny is a software developer => Penny is a software developer
  export function OnlyFirstLetterCapitalString(str) {
    return str? str.replace(/^./, str[0].toUpperCase()):str;
  }

//  [2023-03-22]
//  Created by:- Aanchal Sahu
//  Description:- this function can limit any sentence to given count;EX: penny is a software developer,8 => Penny is ...
// str='Here, we can pass a string.' 
// wordLimit= 4; //Here we need to pass a word limit after which we need to show'...'
export function LimitWordsInString(str, wordLimit) {
  var result = ''
  if (str && wordLimit && str.length > wordLimit) {
    result = str.substring(0, wordLimit);
    result += '...'
  } else {
    result = str
  }
  return result
}
