import { Grid } from "@mui/material";
import React from "react";
import {  useDispatch } from "react-redux";
import { ResendVerificationLink } from "../../action/OnBoardingAction";
import { EButton } from "../../BaseComponents/EButtons";
import { ETypography } from "../../BaseComponents/ETypography";
import { useNavigate } from "react-router";


/**
 * [2022-03-21]
 * changes by: Purva Sharma
 * Description:- Used when verification link (mail) expires
 **/


function LinkExpired(props) {
    const {data} =props
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleResend = () => {
    dispatch(ResendVerificationLink(data,navigate));
  };



  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={10}
      sm={10}
      md={8}
      lg={7}
      xl={5.5}
    >

      <Grid item>
        <ETypography
          variant="h4"
          className="text-align font-size-18px bold-600 mb-16px"
        >
          Email Verification Link Expired
        </ETypography>
        <ETypography sx={{maxWidth:'593px'}} className="font-size-18px font-weight-400 text-align mb-60px">
          Looks like the verification link has expired. Not to worry, we can
          send the link again to your registered email address
          <br/>
          <span className="px-6px font-size-18px bold-600">
            {data?.email}
          </span>
        </ETypography>
      </Grid>

     
      <EButton onClick={() => handleResend()}>Resend Verification Link</EButton>
    </Grid>
  );
}

export default LinkExpired;
