
import {
  EMPLOYEE_FAILURE, EMPLOYEE_LOADING, EMPLOYEE_SUCCESS, EMPLOYEE_PROFILE_LOADING, EMPLOYEE_PROFILE_SUCCESS, EMPLOYEE_PROFILE_FAILURE,
  ALL_EMPLOYEE_FAILURE, ALL_EMPLOYEE_LOADING, ALL_EMPLOYEE_SUCCESS} from '../constants';

const INITIAL_STATE = {
  employeeData: {},
  employeeSuccess: false,
  employeeLoading: false,
  employeeMessage: null,
  //Employee Profile
  employeeProfileData: {},
  employeeProfileSuccess: false,
  employeeProfileLoading: false,
  employeeProfileMessage: null,

  //All Employee List, The Following variables are for storing all the employee's list
  allEmployeeData: {},
  allEmployeeSuccess: false,
  allEmployeeLoading: false,
  allEmployeeMessage: null,
};

const EmployeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMPLOYEE_LOADING:
      return {
        ...state,
        employeeLoading: true,
        employeeData: {},
      };

    case EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeLoading: false,
        employeeSuccess: true,
        employeeData: action.payload.data,
        employeeMessage: action.payload.message,
      };

    case EMPLOYEE_FAILURE:
      return {
        ...state,
        employeeLoading: false,
        employeeSuccess: false,
        employeeMessage: action.payload.message,
        employeeData: {},
      };

      case EMPLOYEE_PROFILE_LOADING:
      return {
        ...state,
        employeeProfileLoading: true,
        employeeProfileSuccess: false,
        employeeProfileData: {},
      };

    case EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        employeeProfileLoading: false,
        employeeProfileSuccess: true,
        employeeProfileData: action.payload.data,
        employeeProfileMessage: action.payload.message,
      };

    case EMPLOYEE_PROFILE_FAILURE:
      return {
        ...state,
        employeeProfileLoading: false,
        employeeProfileSuccess: false,
        employeeProfileMessage: action.payload.message,
        employeeProfileData: {},
      };
      case ALL_EMPLOYEE_LOADING:
        return {
          ...state,
          allEmployeeLoading: true,
          allEmployeeData: {},
        };
  
      case ALL_EMPLOYEE_SUCCESS:
        return {
          ...state,
          allEmployeeLoading: false,
          allEmployeeSuccess: true,
          allEmployeeData: action.payload.data,
          allEmployeeMessage: action.payload.message,
        };
  
      case ALL_EMPLOYEE_FAILURE:
        return {
          ...state,
          allEmployeeLoading: false,
          allEmployeeSuccess: false,
          allEmployeeMessage: action.payload.message,
          allEmployeeData: {},
        };
  
    default:
      return state;
  }
};

export default EmployeeReducer;
