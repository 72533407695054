import React, { useState} from 'react';
import {Stack, Grid, FormControl,FormControlLabel} from "@mui/material";
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { EDIT } from '../../constants';
import { AddNewHolidayValidation } from '../../utils/validation';
import { CreateHoliday, UpdateHoliday } from '../../action/HolidayAction';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import {  ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import moment from 'moment';
import { EDesktopDatePicker } from '../../BaseComponents/EDatePicker';
import { onKeyDown, ReturnBoolean } from '../../utils/CommonFunctions';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { ETextField } from '../../BaseComponents/ETextField';
import { EFormHelperText } from '../../BaseComponents/ETypography';

function HolidaySettingForm(props) {
    const { auth, close, obj, holiday, getFilterData } = props
    const [openDateFrom, setOpenDateFrom] = useState(false);
    const [openDateTo, setOpenDateTo] = useState(false);
    const dispatch = useDispatch();
    const company = useSelector(state => state.CompanyReducer);

    const formik = useFormik({
        initialValues: {
            holiday_name: obj? obj?.holiday_name:'',
            from_date:obj && obj?.from_date? obj?.from_date:'',
            to_date: obj && obj?.to_date? obj?.to_date:'',
            isMultiple: !obj? false: obj.from_date == obj?.to_date? false:true
        },
        validationSchema: AddNewHolidayValidation,
        onSubmit: (data) => {
            data = { ...data, created_by: auth.authData.id, to_date: ReturnBoolean(data?.isMultiple)==false? data?.from_date:data?.to_date};
            if (obj) {
                data = { ...data, id: obj?.id }
                dispatch(UpdateHoliday(auth.authtoken, data, EDIT, getFilterData))
            } else {
                dispatch(CreateHoliday(auth.authtoken, data, getFilterData))
            }
            close && close();
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
    
    return (<>
        <FormikProvider value={formik} id="previewBlock">
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} >
                        <FormControl component="fieldset" error={Boolean(touched.isMultiple && errors.isMultiple)}>
                            <ERadioGroup row aria-label="isMultiple" className="" name="isMultiple" {...getFieldProps('isMultiple')} >
                                <FormControlLabel value={false} control={
                                    <ERadio
                                        onChange={(e, value) => {
                                            setFieldValue("isMultiple", false);
                                        }}
                                        className="py0"
                                    />
                                } label='One Day' />
                                <FormControlLabel value={true} control={
                                    <ERadio
                                        onChange={(e, value) => {
                                            setFieldValue("isMultiple", true);
                                        }}
                                        className="py0"
                                    />
                                } label="Multiple Days" />
                            </ERadioGroup>
                            {touched.isMultiple && <EFormHelperText> {errors.isMultiple}</EFormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ELabelInputMainGrid isModal={true} isfullgrid={true} label={ReturnBoolean(formik?.values?.isMultiple)?"From Date" : "Date"}>
                            <EDesktopDatePicker
                                views={["day"]}
                                inputFormat="dd/MM/yyyy"
                                {...getFieldProps('from_date')}
                                minDate={moment().add(1, 'days').toDate()}
                                maxDate={new Date(company?.companyData?.CompanyActivePlan?.plan_valid_to)}
                                onChange={(newValue) => {
                                    setFieldValue("from_date", newValue ? newValue : '');
                                    !ReturnBoolean(formik?.values?.isMultiple) && setFieldValue("to_date", newValue ? newValue : '') 
                                }}
                                open={openDateFrom}
                                onOpen={() => setOpenDateFrom(true)}
                                onClose={() => setOpenDateFrom(false)}
                                renderInput={(params) => <ETextField
                                    onKeyDown={onKeyDown}
                                    fullWidth  {...params}
                                    onClick={(e) => setOpenDateFrom(true)}
                                    error={Boolean(touched.from_date && errors.from_date)}
                                    helperText={touched.from_date && errors.from_date}
                                    InputLabelProps={{ shrink: true }}
                                  
                                />}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    {ReturnBoolean(formik?.values?.isMultiple) &&
                        <Grid item xs={12} sm={12}>
                            <ELabelInputMainGrid isModal={true} isfullgrid={true} label={"To Date"}>
                                <EDesktopDatePicker
                                    views={["day"]}
                                    inputFormat="dd/MM/yyyy"
                                    minDate={formik?.values?.from_date? moment(formik?.values?.from_date).add(1, 'days').toDate():new Date()}
                                    maxDate={new Date(company?.companyData?.CompanyActivePlan?.plan_valid_to)}
                                    {...getFieldProps('to_date')}
                                    onChange={(newValue) => {
                                        setFieldValue("to_date", newValue ? newValue : '');
                                    }}
                                    open={openDateTo}
                                    onOpen={() => setOpenDateTo(true)}
                                    onClose={() => setOpenDateTo(false)}
                                    renderInput={(params) => <ETextField
                                        onKeyDown={onKeyDown}
                                        fullWidth  {...params}
                                        onClick={(e) => setOpenDateTo(true)}
                                        error={Boolean(touched.to_date && errors.to_date)}
                                        helperText={touched.to_date && errors.to_date}

                                    />}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        <ELabelInputMainGrid isModal={true} isfullgrid={true}  label={"Occasion"}>
                            <ETextField
                                fullWidth
                                placeholder='Occasion'
                                autoComplete="holiday_name"
                                InputLabelProps={{ shrink: true }}
                                {...getFieldProps('holiday_name')}
                                error={Boolean(touched.holiday_name && errors.holiday_name)}
                                helperText={touched.holiday_name && errors.holiday_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>

            </LocalizationProvider>

            <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end' className='modal1-buttons-stick-bottom'>
                <EButtonOutlined size="large" variant="outlined" onClick={() => close()}  > Cancel </EButtonOutlined>
                <ELoadingButton loading={holiday?.holidayLoading} size="large" variant="outlined" type="submit" >{obj ? 'Update' : 'Save'}</ELoadingButton>
            </Stack>

            </Form>
        </FormikProvider>
    </>
    );
}

export default HolidaySettingForm;
