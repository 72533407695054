import axios from 'axios';
import { STATE_LIST_FAILURE, STATE_LIST_SUCCESS, STATE_LIST_LOADING, SERVER_URL, CITY_LIST_FAILURE, CITY_LIST_SUCCESS, CITY_LIST_LOADING } from '../constants';
import { Logout } from './AuthAction';

export function StateList(token) {

    return (dispatch) => {
        dispatch(stateListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/state/state-list`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(stateListSuccess(res.data))
                }
            }).catch((error) => {
                if (error?.response) {
                    dispatch(stateListFailure(error.response.data));
                    // if (error?.response?.status == 401) {
                    // } ^ upper commented code block was blank 
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            });
    };
}


export function stateListLoading() {
    return {
        type: STATE_LIST_LOADING,
    };
}
export function stateListSuccess(payload) {
    return {
        type: STATE_LIST_SUCCESS,
        payload
    };
}

export function stateListFailure(payload) {
    return {
        type: STATE_LIST_FAILURE,
        payload
    };
}

export function CityList(token, state) {
      return (dispatch) => {
          dispatch(cityListLoading());
          axios({
              method: 'get',
              url: `${SERVER_URL}/city/city-list?state_id=${state}`,
              crossDomain: true,
              headers: {
                  "Authorization": 'Bearer ' + token,
              },
          })
              .then((res) => {
                  if (res.status === 200) {
                      dispatch(cityListSuccess(res.data))
                  }
              }).catch((error) => {
                  if (error?.response) {
                      dispatch(cityListFailure(error.response.data));
                      if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                  }
              });
      };
  }
  

export function cityListLoading() {
    return {
        type: CITY_LIST_LOADING,
    };
}
export function cityListSuccess(payload) {
    return {
        type: CITY_LIST_SUCCESS,
        payload
    };
}

export function cityListFailure(payload) {
    return {
        type: CITY_LIST_FAILURE,
        payload
    };
}


