


import {  Grid  } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography } from '../../BaseComponents/ETypography';
import { HRAccountantPaymentAction } from '../../action/PaymentAction';
import { useDispatch } from 'react-redux';

const PaymentRequestDelete = (props) => {
    const { setModalState, auth, selectedData, getPaymentFilterData } = props;
    const dispatch = useDispatch();

    const HandleDeleteRequest = () => {
        dispatch(HRAccountantPaymentAction(auth?.authtoken, { payment_id: selectedData.modalData?.id }, getPaymentFilterData, "delete_request"));
        setModalState({ modalData: null, modalName: null })
    }

    return (

        <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ETypography variant='h6'>Are you sure you want to delete this payment request? </ETypography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Cancel
                    </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" onClick={() => HandleDeleteRequest()} >Delete</ELoadingButton>
                </Stack>
            </Grid>
        </Grid>

    );
};
export default PaymentRequestDelete;
