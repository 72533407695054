
import { TODAY_GRAPH_FAILURE, TODAY_GRAPH_SUCCESS, TODAY_GRAPH_LOADING } from '../constants';

const INITIAL_STATE = {
    checkInOutData: {},
    checkInOutSuccess: false,
    checkInOutLoading: false,
    checkInOutMessage: null,
};

const EmployeeAttendanceGraphReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case TODAY_GRAPH_LOADING:
            return {
                ...state,
                checkInOutLoading: true,
                checkInOutData: {}
            };

        case TODAY_GRAPH_SUCCESS:
            return {
                ...state,
                checkInOutLoading: false,
                checkInOutSuccess: true,
                checkInOutData: action.payload.data,
                checkInOutMessage: action.payload.message,
            };

        case TODAY_GRAPH_FAILURE:
            return {
                ...state,
                checkInOutLoading: false,
                checkInOutSuccess: false,
                checkInOutMessage: action.payload.message,
            };

        default:
            return state;
    }
};

export default EmployeeAttendanceGraphReducer;
