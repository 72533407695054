import {PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT } from "../constants"


/**
 * [2023-05-01]
 * Changes By: Purva Sharma
 * Description:- This function provides payment  category option in the dropdown of search bar / modal etc
 *             - Props: setting: the setting of the module, list: the list from which it has to filter the option
 *             - used in: [PaymentRequestModal, PaymentLogSearchBar,PaymentRequestSearchBar,TalentEditPayment]
 **/


 // getPaymentCategory : gets payment category option base on setting and role

 export const getPaymentCategory = (setting,list)=>{
    switch (setting) {
        case 'ENABLE_BOTH':
            return list
        case 'ENABLE_REIMBURSEMENT':
            return list.filter((item)=>item.id !== PAYMENT_CATEGORY_ADVANCE)
        case 'ENABLE_ADVANCE_SALARY':
            return list.filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT)
        case 'DISABLE_BOTH':
            return list.filter((item)=>item.id !== PAYMENT_CATEGORY_ADVANCE ).filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT)
        default:
            return list
    }
}
