import { Divider, styled } from "@mui/material";

export const EDivider = styled(Divider)(({ theme }) => ({

}));


export const EDividerCard = styled(Divider)(({ theme }) => ({
    borderColor: theme.palette.border.light + "!important",
}));

export const EDividerNavbar = styled(Divider)(({ theme }) => ({
    marginTop: '16px',
    marginBottom: '16px',
    marginRight:'10px',
    marginLeft:'10px',
    height:'44px',
    borderColor: theme.palette.border.divider1
}));

export const EDividerVertical = styled(Divider)(({ theme }) => ({
    height:'29px',
}));