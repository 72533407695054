import { Pagination, styled, TablePagination } from "@mui/material";

export const ETablePagination = styled(TablePagination)(({ theme }) => ({
    button: {
        color: theme.palette.text.button +'!important',
        '&.Mui-disabled':{
            color:theme.palette.text.disabledBottonText +'!important'
        }
    },
    
}));


export const ECardPagination = styled(Pagination)(({ theme }) => ({
    button: {
        color: theme.palette.text.button +'!important',
        '&.Mui-disabled':{
            color:theme.palette.text.disabledBottonText
        },
        '&.Mui-selected':{
            background: theme.palette.border.border6//'red !important'
        }
    },
}));