import {
  LOGIN_LINK_SUCCESS, LOGIN_LINK_FAILURE, LOGIN_LINK_LOADING,LOGIN_LINK_CLEAR
} from '../constants';

const INITIAL_STATE = {
  authLinkEmail: null,
  authLinkLoading: false,
  authLinkSuccess: false,
  authLinkMessage: null,
  authResetLoading: false,
};

const authResetLinkReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case LOGIN_LINK_LOADING:
      return {
        ...INITIAL_STATE,
        authLinkEmail: action.payload ? action.payload : null ,
        authResetLoading: true,
      };
    case LOGIN_LINK_SUCCESS:
      return {
        authLinkEmail: action.payload,
        authLinkLoading: false,
        authLinkSuccess: true,
        authLinkMessage: null,
        authResetLoading: false,
      };
    case LOGIN_LINK_FAILURE:
      return {
        authLinkEmail: null,
        authLinkLoading: false,
        authLinkSuccess: false,
        authLinkMessage: null,
        authResetLoading: false,
      };
    case LOGIN_LINK_CLEAR:
      return INITIAL_STATE;
      
    default:
      return state;
  }
};

export default authResetLinkReducer;
