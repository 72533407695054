import { Grid, styled } from "@mui/material";

export const FieldContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    padding:'0 10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    maxWidth: '100%',
    "& > label": {
        width: '50%',
        paddingRight:'3%',//to avoid overlapping of label and colon
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        "&::after": {
            content: '": "',
            position: 'absolute',
            right: '5%',
            top: 0
        },
    },
}));

export const FieldContainerFullWidth = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    maxWidth: '100%',
    "& > label": {
        width: '19%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        "&::after": {
            content: '": "',
            position: 'absolute',
            right: '5%',
            top: 0
        },
    },
}));


/**
 * [2023-04-26]
 * Changes By: Purva Sharma
 * Description:- added hideColon prop
 **/


export const FieldContainerCustom = styled(Grid)(({ theme, labelwidth, hidecolon }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    maxWidth: '100%',
    "& > label": {
        width: `${labelwidth ? labelwidth : 15}%`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        "&::after": {
            content: `${hidecolon ? '" "' : '": "'}`  ,
            position: 'absolute',
            right: '5%',
            top: 0
        },
    },
    "& .MuiBox-root": {
        width: `${labelwidth ? (100 - labelwidth) : 85}%`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));