import styled from "@emotion/styled";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

export const ETabContext = styled(TabContext)((({ theme }) => ({
})));

export const ETabList = styled(TabList)((({ theme, alignitems = null }) => ({
    display: 'flex',
    // alignItems:'baseline',
    '& .MuiTabs-flexContainer': {
        justifyContent: `${alignitems ? alignitems : 'space-between'}`,//employeeData?.show_as_talent != true ? 'left!important' : '',
        // padding: '0!important',
        // height:'40px'
    },
    '& .MuiTab-root': {
        margin: '0 1rem 0 0', 
        padding: '5px 10px',
        justifyContent: 'flex-end!important',
    },
})));

export const ETabListHR = styled(TabList)((({ theme, alignitems = null }) => ({
    display: 'flex',
    // alignItems:'baseline',
    '& .MuiTabs-flexContainer': {
        justifyContent: `${alignitems ? alignitems : 'space-between'}`,//employeeData?.show_as_talent != true ? 'left!important' : '',
        // padding: '0!important',
        // height:'40px'
    },
    '& .MuiTab-root': {
        margin: '0 1rem 0 0', 
        padding: '5px 10px',
        justifyContent: 'center !important',
    },
})));


export const ETab = styled(Tab)((({ theme }) => ({
    color: theme.palette.border.staticBorder7 +'!important',
    fontWeight:'600',
    textTransform: 'none',
    fontSize:'1rem',
    '&.Mui-selected':{
        color: theme.palette.text.primary +'!important',
        fontWeight:'600'

    }
})));

export const ETabPanel = styled(TabPanel)((({ theme }) => ({

})));

// This tab is regarding the vertical tab in HR dashboard
export const ETabVertical = styled(Tab)((({ theme }) => ({
    color: theme.palette.border.staticBorder7 +'!important',
    fontWeight:'600',
    textTransform: 'none',
    fontSize:'1rem',
    '&.Mui-selected':{
        color: theme.palette.text.primary +'!important',
        background: theme.palette.background.dullLightOrange +'!important',
        fontWeight:'600'

    }
})));

export const ETabVerticalHR = styled(Tab)((({ theme }) => ({
    color: theme.palette.text.primary +'!important',
    fontWeight:'600',
    textTransform: 'none',
    fontSize:'1rem',
    display: 'flex',
    // alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '&.Mui-selected':{
        color: theme.palette.text.primary +'!important',
        background: theme.palette.background.dullLightOrange +'!important',
        fontWeight:'600',
        border:'0'

    }
})));

export const ETabVerticalHRLeave = styled(Tab)((({ theme }) => ({

    '&.MuiTab-root': {
        justifyContent: 'space-between !important',
        padding:'0 10px !important'
    },
    color: theme.palette.text.primary +'!important',
    fontWeight:'600',
    textTransform: 'none',
    padding:'0!important',
    fontSize:'1rem',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    width:'100%',
    marginBottom:'5px !important',
    '&.Mui-selected':{
        color: theme.palette.text.primary +'!important',
        background: theme.palette.background.dullLightOrange +'!important',
        fontWeight:'600',
        border:'0',
    },      
})));