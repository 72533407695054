export function numberWithCommas(num, isRs = null) {
  let number = Number(num)
  if (!isRs) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
  }
  else {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, }).format(number);
  }
}


export function AddGstPercentage(number, gst, isNotRs = null) {
  let x = (Number(gst) / 100) * Number(number);
  x += number;
  if (!isNotRs) { x = numberWithCommas(x); }
  return x;
}