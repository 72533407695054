import {
    SETTING_ACCOUNT,
    SETTING_ADDITIONAL,
    SETTING_ATTENDANCE,
    SETTING_PAYROLL,
    SETTING_FAILURE,
    SETTING_LEAVE,
    SETTING_LOADING_STOP,
    SETTING_LOADING_START,
    SETTING_PAYMENT,
    SETTING_TALENT
} from '../constants';

/**
 * This reducer is used
 */
const INITIAL_STATE = {
    settingError: {},
    settingLoading: false,
    additionSettingData: {},
    attendanceSettingData: {},
    accountSettingData: {},
    leaveSetting: {},
    talentSettingData: {},
    paymentSettingData: {},
    payrollSettingData:{}
};

const SettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SETTING_LOADING_START:
            return {
                ...state,
                settingLoading: true,
            };
        case SETTING_LOADING_STOP:
            return {
                ...state,
                settingLoading: false,
            };
        case SETTING_ADDITIONAL:
            return {
                ...state,
                additionSettingData: action.payload,
            };
        case SETTING_ATTENDANCE:
            return {
                ...state,
                attendanceSettingData: action.payload,
            };
        case SETTING_ACCOUNT:
            return {
                ...state,
                accountSettingData: action.payload,
            };
        case SETTING_PAYROLL:
            return {
                ...state,
                payrollSettingData: action.payload,
            };
        case SETTING_LEAVE:
            return {
                ...state,
                leaveSetting: action.payload
            };
        case SETTING_TALENT:
            return {
                ...state,
                talentSettingData: action.payload
            };
        case SETTING_PAYMENT:
            return {
                ...state,
                paymentSettingData: action.payload
            };
        case SETTING_FAILURE:
            return {
                ...state,
                settingError: action.payload
            };

        default:
            return state;
    }
};

export default SettingReducer;
