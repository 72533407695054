import { CircularProgress, Grid } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmployeeRequestPaymentList } from '../../action/PaymentAction';
import { EBoxPage } from '../../BaseComponents/EBox';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { EButtonOutlined, EIconButton } from '../../BaseComponents/EButtons';
import EChip from '../../BaseComponents/EChip';
import { EDivider } from '../../BaseComponents/EDivider';
import { EIcon, EIconSmall } from '../../BaseComponents/EIcon';
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import RupeeIcon from '@iconify/icons-material-symbols/currency-rupee';
import Page from '../../BaseComponents/EPage';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { APPROVED_STATUS, CANCELLED_STATUS, DEFAULT_ROWS_PERPAGE, PENDING_STATUS, ROW_SIZE_PER_PAGE} from '../../constants';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import { numberWithCommas } from '../../utils/formatNumber';
import { capitalizeCapitalString } from '../../utils/formatText';
import EModal from '../../BaseComponents/EModal';
// import PaymentActionModal from '../../PageComponents/Payment/PaymentActionModal';
// import PaymentDirectPayment from '../../PageComponents/Payment/PaymentDirectPayment';
// import { useRef } from 'react';
// import PaymentBulkModal from '../../PageComponents/Payment/PaymentBulkModal';
import PaymentLogSearchBar from '../../PageComponents/Payment/PaymentLogSearchBar';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import FreelancerPaymentActionModal from '../../PageComponents/Payment/FreelancerPaymentActionModal';


function FreelancerPayment(props) {
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //   const auth = useSelector((state) => state.authReducer);
    const payment = useSelector((state) => state.PaymentReducer);
    // const employee = useSelector((state) => state.EmployeeReducer); //INFO: commenting this since it's of no use as of now
    const company = useSelector(state => state.CompanyReducer);
    const [modalState, setModalState] = useState({ modalData: null, modalName: null });


    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [page, setPage] = useState(0);



    const [SearchParameters, setSearchParameters] = useState({
        yearMonth: null,
        contract_no: '',
        sortBy: null,
        employee_id: null,
        particular: null,
        start_date: null,
        end_date: null,
        freelancer_id:null
    });

    const getFilterData = (searchData) => {
        callApi(searchData, 0, rowsPerPage);
        setPage(0);

    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...SearchParameters }, page, rowsPerPage);
            // dispatch(FreelancerListData(auth?.authtoken)); //INFO: calling this api in the search component
            // dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
        }
    }, [auth?.authtoken])


    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(EmployeeRequestPaymentList(auth.authtoken, 'request_freelancer', { ...fnParams, sort: !fnParams.sort ? ' DESC ' : ' ASC ' }, fnPage, fnRowPerPage));
    }

    return (
        <Page title="Freelancer Payment">

            <Grid container display="row" justifyContent="space-between " className='align-center' >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Freelancer Payment'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Payment', href: '' },
                            {
                                name: 'Freelancer Payment',
                            }
                        ]}
                    /></Grid>
                <Grid item >

                    <EButtonOutlined className='button-left-margin' variant="outlined"
                        onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Freelancer Payment</ETypographyPageHeading>
                </Grid>
                <Grid lg={12} xs={12} item className='pt-8px'><EDivider /></Grid>
            </Grid>
            <EBoxPage>

                <PaymentLogSearchBar
                    setSearchParam={setSearchParameters}
                    searchParam={SearchParameters}
                    getFilterData={getFilterData}
                    // freelancerList={freelancerList} INFO: commented this since we have got it in search bar component itself
                    employeeList={null}
                    companyData={company}
                    auth={auth}
                />


                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {payment?.PaySalaryListData?.count > 0 && (
                                <EListHead

                                    headLabel={TABLE_HEAD}
                                    rowCount={payment?.PaySalaryListData?.rows?.length}
                                    onRequestSort={() => null}
                                />
                            )}

                            {payment?.PaySalaryListData?.PaySalaryListLoading && (
                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <CircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            )}
                            {payment?.PaySalaryListData?.count > 0 && (
                                <ETableBody>
                                    {payment?.PaySalaryListData?.rows?.map((row, index) => {
                                        const {
                                            freelancerData,
                                            transaction_date,
                                            transaction_status,
                                            ContractWorkFace, payment_medium, pay_amount, accountant_remark, transaction_status_date
                                        } = row;
                                        return (
                                            <ETableRow hover key={index} tabIndex={-1} >

                                                <ETableCell align="left">
                                                    {transaction_date ? moment(transaction_date).format('DD/MM/yyyy') : '-'}
                                                </ETableCell>
                                                <ETableCell style={{ minWidth: '130px' }} align="left">
                                                    <Grid container>
                                                        <Grid item xl={8} lg={8} >
                                                            <ETypography
                                                                variant="subtitle1"
                                                                // noWrap
                                                                className=" text-capitalize dark-grey-color"
                                                            >
                                                                {freelancerData?.freelancer_name ? freelancerData?.freelancer_name : '-'}
                                                            </ETypography>
                                                            {freelancerData?.freelancer_code ? freelancerData?.freelancer_code : ''}
                                                        </Grid>
                                                    </Grid>
                                                </ETableCell>
                                                <ETableCell align="left" style={{ minWidth: '320px' }}>
                                                    <Grid container>
                                                        <Grid item xl={8} lg={8} style={{ mt: 0 }}>
                                                            <ETypography
                                                                variant="subtitle1"
                                                                
                                                                className="text-capitalize dark-grey-color"
                                                            >
                                                                {ContractWorkFace?.ContractWork?.contract_title ? ContractWorkFace?.ContractWork?.contract_title : '-'}
                                                            </ETypography>
                                                            {ContractWorkFace?.ContractWork?.contract_code ? ContractWorkFace?.ContractWork?.contract_code : '-'}
                                                        </Grid>
                                                    </Grid>
                                                </ETableCell>

                                                <ETableCell align="right" style={{ minWidth: '120px' }}>
                                                    {pay_amount ? numberWithCommas(pay_amount) : '-'}
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    <span>
                                                    {payment_medium ? capitalizeCapitalString(payment_medium): '-'}
                                                    </span>
                                                 
                                                </ETableCell>
                                                <ETableCell align="left">{transaction_status_date && transaction_status != PENDING_STATUS && accountant_remark ? moment(transaction_status_date).format('DD/MM/yyyy') : '-'}</ETableCell>
                                                <ETableCell style={{ minWidth: '120px' }} >
                                                    <Grid className='display-flex align-items-center'>
                                                        {transaction_status ? (
                                                            <EChip
                                                                label={
                                                                    transaction_status == PENDING_STATUS
                                                                        ? 'Pending' : 'Paid'}
                                                                className={
                                                                    transaction_status == PENDING_STATUS
                                                                        ? 'active-grey-chip'
                                                                        : transaction_status == APPROVED_STATUS
                                                                            ? 'active-green-chip'
                                                                            : transaction_status == CANCELLED_STATUS ? 'active-red-chip' : 'active-blue-chip'
                                                                }
                                                            />
                                                        ) : (
                                                            '-'
                                                        )}
                                                        {transaction_status != PENDING_STATUS && accountant_remark &&
                                                            <ETooltip title={accountant_remark} arrow>
                                                                <EIconButton sx={{ color: 'grey.500' }}>
                                                                    <EIconSmall icon={InfoIcon} width={23} height={23} />
                                                                </EIconButton>
                                                            </ETooltip>}
                                                    </Grid>
                                                </ETableCell>
                                                <ETableCell align="right" style={{ width: '20px' }}>
                                                    {/* {(auth?.authData?.role == SUPER_ADMIN && auth?.authData?.is_primary == true)} */}
                                                    <ETooltip title={row?.transaction_status == PENDING_STATUS ?'Pay':'View'} arrow>
                                                        <EIconButton
                                                            onClick={() => setModalState({ modalData: row, modalName: 'PAYMENT' })}
                                                        >

                                                            {row?.transaction_status == PENDING_STATUS ? (

                                                                <EIcon icon={RupeeIcon} className={'theme-color-static'} />
                                                            ) : (
                                                                <EIcon icon={VisibilityIcon} className={'grey-color-static'} />
                                                            )}
                                                        </EIconButton>
                                                    </ETooltip>
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            )}
                            {(payment?.PaySalaryListData?.count == 0 || Object.keys(payment?.PaySalaryListData)?.length == 0) &&
                                !payment?.PaySalaryListData?.PaySalaryListLoading && (
                                    <ETableBody>
                                        <ETableRow>
                                            <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <SearchNotFound />
                                            </ETableCell>
                                        </ETableRow>
                                    </ETableBody>
                                )}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {payment?.PaySalaryListData?.count > DEFAULT_ROWS_PERPAGE && (
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={payment?.PaySalaryListData?.count ? payment?.PaySalaryListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}



                {modalState.modalName && modalState.modalName === 'PAYMENT' &&
                    <EModal open={modalState.modalName === 'PAYMENT'}
                        headervalue={"Freelancer Payment"}
                    >
                        <FreelancerPaymentActionModal
                            selectedData={modalState}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                        />

                    </EModal>}

                {/* {modalState.modalCategory &&
                    <EModal open={modalState.modalCategory === 'DIRECTPAYMENT'}
                        headervalue={'Direct Payment'}
                    >
                        <PaymentDirectPayment
                            selectedData={modalState}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                            employeeList={employee}
                        />

                    </EModal>} */}
                {/* 

                {modalState.modalCategory &&
                    <EModal open={modalState.modalCategory === 'BULKPAYMENT'}
                        headervalue={'Bulk Payment'}
                    >
                        <PaymentBulkModal
                            paymentData={modalState.modalData}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                        />

                    </EModal>} */}

            </EBoxPage>
        </Page>
    );
}


const TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
    { id: 'FreelancerName', label: 'Freelancer Name', alignRight: false },
    { id: 'ContractName', label: 'Contract Name', alignRight: false },
    { id: 'Amount', label: 'Amount', alignRight: true },
    { id: 'PaymentMode', label: 'Payment Mode', alignRight: 'center' },
    { id: 'TransactionDate', label: 'Transaction Date', alignRight: false },
    { id: 'Status', label: 'Status', alignRight: false },
    { id: 'Action', label: 'Action', alignRight: 'center' }
];

export default FreelancerPayment;
