import { Grid, Stack } from '@mui/material'
import React from 'react'
import { ETypography } from '../../../BaseComponents/ETypography'
import Page from '../../../BaseComponents/EPage';
import { AuthNavbar } from '../../../layouts/auth-navbar'
import { AuthRootStyle } from '../../../BaseComponents/EContainer'
import { ECard, ECardDashboard } from '../../../BaseComponents/ECard'
import { EButton } from '../../../BaseComponents/EButtons'
import { EBox } from '../../../BaseComponents/EBox';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onBoardingStepAction } from '../../../action/OnboardingStepAction';

function OnboardingThankyouResponse(auth) {
    const dispatch = useDispatch()
    const navigateTo = useNavigate()
    const clickHandle = ()=>{
        dispatch(onBoardingStepAction(auth?.auth?.authtoken, 'SCHEDULE'))
        navigateTo('/')
    }
 
    return (
        <Page title="Thankyou">
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <AuthRootStyle maxWidth="lg">
                    <AuthNavbar />
                    <ECard sx={{ maxWidth: '570px', margin:'28px auto',paddingX:'28px' }}>
                        <EBox>
                            <ETypography className='bold-600 font-size-24px mb-24px text-align'>
                                Thank you for your response.
                            </ETypography>

                            <ETypography className='font-size-16px bold-400 mb-24px'>
                                One of our team's experts will contact you on your registered contact number to assist with onboarding you to Entera Payroll.


                            </ETypography>


                            <ETypography className='font-size-16px bold-400'>
                                Meanwhile explore Entera Payroll.
                            </ETypography>


                            <Stack direction={'row'} justifyContent={'flex-end'} className='mt-24px'>
                                <EButton onClick={()=>clickHandle()}>
                                    Explore
                                </EButton>

                            </Stack>


                        </EBox>
                    </ECard>

                </AuthRootStyle>
            </Grid>
        </Page>
    )
}

export default OnboardingThankyouResponse