import axios from "axios";
import { Errors, Success } from "../BaseComponents/EToast";
import {
  GLOBAL_USER_SETTING_FAILURE, GLOBAL_USER_SETTING_LOADING, GLOBAL_USER_SETTING_SUCCESS,
  SERVER_URL, SET_NOTIFICATION_SETTING_API,
  USER_SETTING_FAILURE, USER_SETTING_LOADING, USER_SETTING_SUCCESS
} from "../constants";
import { Logout } from "./AuthAction";


// INFO: This settings is used to get data (GET APIs)
export function GetUserSettings(token, url) {

  let urlString = url;

  return (dispatch) => {
    dispatch(userSettingLoading());
    axios({
      method: 'GET',
      url: `${SERVER_URL}/user-settings/${urlString}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(userSettingSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(userSettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

// INFO: This settings is used to save data (POST APIs)
export function SetUserSettings(token, url, Data = null, getUrl) {

  let urlString = url;
  let data = ''

  if (url == SET_NOTIFICATION_SETTING_API) {
    // data = new FormData();
    // data.append('notification_is_default', Data.notification_is_default);
    // data.append('notification_is_checked', Data.notification_is_checked);
    // data.append('notification_setting_data', Data?.notification_setting_data)

    var payload = {
      notification_setting_data: JSON.parse(Data?.notification_setting_data),
      notification_is_default: Data.notification_is_default,
      notification_is_checked: Data.notification_is_checked,
    };
  }

  return (dispatch) => {
    dispatch(userSettingLoading(Data));
    axios({
      method: 'POST',
      url: `${SERVER_URL}/user-settings/${urlString}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: payload
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          if (getUrl) { dispatch(GetUserSettings(token, getUrl)); }
          dispatch(GlobalUserSettingsAction(token))
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(userSettingFailure(error.response.data));
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data}</span>`,
          })
          if (getUrl) { dispatch(GetUserSettings(token, getUrl)); }
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function userSettingLoading(payload) {
  return {
    type: USER_SETTING_LOADING,
    payload
  };
}

export function userSettingSuccess(payload) {
  return {
    type: USER_SETTING_SUCCESS,
    payload
  };
}

export function userSettingFailure(payload) {
  return {
    type: USER_SETTING_FAILURE,
    payload
  };
}


// INFO: This settings is used to get the global user settings
export function GlobalUserSettingsAction(token) {

  return (dispatch) => {
    dispatch(globalUserSettingLoading());
    axios({
      method: 'GET',
      url: `${SERVER_URL}/user-settings/get-settings`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(globalUserSettingSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(globalUserSettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function globalUserSettingLoading(payload) {
  return {
    type: GLOBAL_USER_SETTING_LOADING,
    payload
  };
}
export function globalUserSettingSuccess(payload) {
  return {
    type: GLOBAL_USER_SETTING_SUCCESS,
    payload
  };
}

export function globalUserSettingFailure(payload) {
  return {
    type: GLOBAL_USER_SETTING_FAILURE,
    payload
  };
}