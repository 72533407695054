import React from 'react'
import { EBox } from '../../BaseComponents/EBox'
import { EAvatar, EAvatarHrDashboard, EAvatarHrDashboardBirthdayToday, EAvatarHrDashboardBirthdayUpcoming } from '../../BaseComponents/EAvatar'
import { ETypography } from '../../BaseComponents/ETypography'
import EModal from '../../BaseComponents/EModal'
import { useState } from 'react'
import ModalList, { CancelModal } from './ModalList'
import { getInitials } from '../../utils/CommonFunctions'
import { ETooltip } from '../../BaseComponents/ETooltip'

const BirthdayComp = (props) => {
    const {today = [], afterToday = [], matchesXL, type, isHr} = props
    
    const [header, setHeader] = useState('')
    

    let firstItemsCount, remainingBdayCount, firstUpcomingItemsCount, firstItems, firstUpcomingItems, remainingAnniversaryCount, remainingItems, remainingUpcomingItems

    //if screen size is "lg" then we need to show 3 avatars in today section, if it is less than we will show 2 avatars
    {matchesXL==true? firstItemsCount=3: firstItemsCount=2}

    //if screen size is "lg" then we need to show 4 avatars in Upcoming section, if it is less than we will show 3 avatars
    {matchesXL==true? firstUpcomingItemsCount=4: firstUpcomingItemsCount=3}

    //logic for number of avatars
    // as we want different number of avatars in diff screen
    if(today?.length > firstItemsCount){
         firstItems = today?.slice(0,firstItemsCount) // slicing first avatar's which we will show in dashboard
         remainingItems = today.slice(firstItemsCount) // slicing components which is combined and is shown as one avatar
         remainingBdayCount = today?.length - firstItems.length   //length to display how many more birthadys are today , apart from the first 2 or 3 shown.
    }
    else{
         firstItems = today
         remainingItems = []
         remainingBdayCount = 0
    }


    if(afterToday?.length > firstItemsCount){
        firstUpcomingItems = afterToday?.slice(0,firstItemsCount) // slicing first avatar's which we will show in dashboard
        remainingUpcomingItems = afterToday?.slice(firstItemsCount) // slicing components which is combined and is shown as one avatar
        remainingAnniversaryCount = afterToday?.length - firstUpcomingItems.length //length to display how many more birthadys are today , apart from the first 2 or 3 shown.
   }
   else{
        firstUpcomingItems = afterToday
        remainingUpcomingItems= []
        remainingAnniversaryCount = 0
   }

    const [open, setOpen] = useState(false)

    const [modalData, setModalData] = useState([])


  return (
    <> 
        <EBox>

            {
                // Checking if there is no "today's data" and no "upcoming's data"
                // because we want to display a big no data image if both the data is not present
                today.length == 0 && afterToday.length == 0 
            ?
                // as both the data is not present
                //  we will display big image with text that no birthday or anniversary today
                <EBox sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100% !important'}}>

                    <img className="no-data-image" 
                        src={type == 1?"/assets/images/hrDashboardNodata/birthdays.svg":"/assets/images/hrDashboardNodata/work anniversary.svg" }
                        alt="No data" 
                        style={{width:'60%', marginBottom:'4px', marginTop:'10%'}}
                    />

                    <ETypography sx={{fontSize:'16px', fontWeight:'500'}}>
                        {type == 1 ? "No Birthday Today" : "No Anniversary Today"}
                    </ETypography>
                </EBox>
            :
            <>
                {/* if any of the data is available we will display the following */}

                <EBox sx={{display:'flex', flexDirection:'column',  height:'100% !important'}}>
                    {/* Modal to display when user clicks on any avatar */}
                    <EModal open={open} headervalue={header} footeractions={<CancelModal open={open} setOpen={setOpen}/>} newMaxWidth='xs'>
                            <ModalList modalData={modalData} type={type}/>
                    </EModal>
                    
                    {/* Heading */}
                    <EBox className="font-size-20px bold-400 mb-8px mt-16px" sx={{lineHeight:'28px'}}>Today</EBox>

                    <EBox className="display-flex mb-8px">
                        {
                            // Checking if there is no "today's data"
                            // because we want to display a small image if the data is not present
                            today?.length > 0 
                        ? 
                            <>
                                {/* if today's data is present */}
                                {firstItems?.map((row, index) => {
                                return (
                                    <ETooltip key={index} title={row?.employee_name} arrow>                    
                                        <EAvatarHrDashboardBirthdayToday className="mr-24px cursor-pointer" key={row}
                                            onClick={()=>{
                                                setOpen(!open)
                                                setModalData(today)
                                                type==1? setHeader("Today's Birthday") : setHeader("Today's Work Anniversary")
                                            }
                                            }>
                                            {row?.employee_image == null || row?.employee_image == '' || row.employee_image==undefined?
                                                <span className="font-size-20px theme-color-static">{getInitials(row.employee_name)}</span>:
                                                <img alt='' width={"75px"} src={row.employee_image} className='cursor-pointer'/>
                                            }
                                        </EAvatarHrDashboardBirthdayToday>
                                    </ETooltip>
                                )
                            })}
                            {remainingBdayCount > 0 && 
                                    <EAvatarHrDashboardBirthdayToday className="" onClick={()=>{
                                            setOpen(!open) 
                                            setModalData(today)
                                            type==1? setHeader("Today's Birthday") : setHeader("Today's Work Anniversary")
                                        }}   
                                    >
                                        <ETypography sx={{fontWeight: 600, fontSize: '24px', lineHeight: '140%'}} className='cursor-pointer color-text-primary'>
                                            {remainingBdayCount + "+" }
                                        </ETypography>
                                    </EAvatarHrDashboardBirthdayToday>}
                            </>
                        : 
                            <>
                                {/* if only today's data is not present */}
                                
                                    <NoDataBirthdayBox 
                                        heading= {type ==1? "No Birthday Today" : "No Work Anniversary Today"} 
                                        src = {type==1? '/assets/images/hrDashboardNodata/birthdays.svg' : '/assets/images/hrDashboardNodata/work anniversary.svg'}
                                    />
                            </>
                        }
                    </EBox>
                    
                    {/* Heading */}
                    
                    <>
                        <EBox className="font-size-20px bold-400 mb-8px mt-16px" sx={{lineHeight:'28px'}}>Upcoming</EBox>

                        <EBox className="display-flex mb-8px">
                            {
                                // Checking if there is no "upcoming's data"
                                // because we want to display a small image if the data is not present
                                afterToday.length > 0
                            ?
                                <>
                                    {/* if upcoming's data is present */}
                                    {firstUpcomingItems?.map((row, index) => {
                                        return (
                                            <ETooltip key={index} title={row?.employee_name} arrow>  
                                            <EAvatarHrDashboardBirthdayUpcoming className="mr-24px cursor-pointer" key={row} onClick={()=>{
                                                    setOpen(!open)
                                                    setModalData(afterToday)
                                                    type==1? setHeader("Upcoming Birthdays") : setHeader("Upcoming Work Anniversary")
                                                }}
                                                    >
                                                    {row?.employee_image == null || row?.employee_image == '' || row.employee_image==undefined?
                                                    <span className="font-size-20px theme-color-static">{getInitials(row.employee_name)}</span>:
                                                    <img alt='' width={"75px"} src={row.employee_image} />
                                                    
                                                }
                                            </EAvatarHrDashboardBirthdayUpcoming>
                                            </ETooltip>
                                        )
                                    })}
                                    {remainingAnniversaryCount > 0 && 
                                        <EAvatarHrDashboardBirthdayUpcoming className="" onClick={()=>{
                                                setOpen(!open)
                                                setModalData(afterToday)
                                                type==1? setHeader("Upcoming Birthdays") : setHeader("Upcoming Work Anniversary")
                                                }}>
                                            <ETypography sx={{fontWeight: 600, fontSize: '24px', lineHeight: '140%'}} className='cursor-pointer'>
                                                {remainingAnniversaryCount + "+" }
                                            </ETypography>
                                        </EAvatarHrDashboardBirthdayUpcoming>}
                                </>
                            :
                            <>
                                {/* if only upcoming's data is not present */}
                                <NoDataBirthdayBox 
                                    heading= {type ==1? "No Upcoming BIrthday" : "No Upcoming Work Anniversary"}
                                    src = {type==1? '/assets/images/hrDashboardNodata/birthdays.svg' : '/assets/images/hrDashboardNodata/work anniversary.svg'}
                                />
                            </>

                            }

                        </EBox>
                    </>
                    
                </EBox>
            </>
            }

        </EBox>
    </> 
        
  )
}

export default BirthdayComp


const NoDataBirthdayBox = (props) => {
    const { children, heading = "",  src} = props;
    return (
        <EBox
            sx={{display:'flex', flexDirection:'column', alignItems:'center !important', width:'100%'}}
        >
            <img src={src} style={{maxHeight:'100px', marginBottom:'10px'}}/>
            <ETypography sx={{fontSize:'16px', fontWeight:'500'}}>{heading}</ETypography>
        {children}
        </EBox>
    );
  };


