import { useState } from "react";
import SalaryPayroll from "./SalaryPayroll";
import SalaryPayrollDetail from "./SalaryPayrollDetail";


const SalaryDueMainPage = (props) => {
    const { tabvalue, paymentList, auth, setTabValue } = props;
    const [showDetailPage, setShowDetailPage] = useState({ isShow: false, payrollId: null });

    return (
        <>
            {!showDetailPage.isShow ?
                <SalaryPayroll
                    tabvalue={tabvalue}
                    auth={auth}
                    paymentList={paymentList}
                    setShowDetailPage={setShowDetailPage}
                /> :
                <SalaryPayrollDetail
                    setShowDetailPage={setShowDetailPage}
                    tabvalue={tabvalue}
                    auth={auth}
                    showDetailPage={showDetailPage}
                />
            }
        </>


    );
};

export default SalaryDueMainPage;