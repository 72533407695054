import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Hook that prevents navigation away from the current page when a form has unsaved changes.
 * @param {boolean} when - Whether to enable the blocker or not.
 * @param {string} message - The message to show in the custom modal when navigating away with unsaved changes.
 * @returns {[boolean, function, function]} - An array containing the state of the custom modal, a function to show/hide it, and a function to continue the navigation after confirming.
 */
export const useBlocker = (when, message = 'Are you sure you want to discard changes?') => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const prevLocationRef = useRef();

  useEffect(() => {
    /**
     * Event handler for the "beforeunload" event.
     * Prevents the browser from closing or navigating away from the current page when the form has unsaved changes.
     */
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    /**
     * Event handler for click events on links.
     * Prevents navigation away from the current page when the form has unsaved changes.
     * Shows a custom modal with "Save" and "Discard" buttons to confirm the navigation.
     */
    const handleClick = (event) => {
      if (when) {
        event.preventDefault();
        navigate(location.pathname, { replace: false });
        setShowModal(true);
        prevLocationRef.current = event.currentTarget.pathname;
      }
    };

    // Add event listeners for "beforeunload" and click events on links
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', handleClick);
    });

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.querySelectorAll('a').forEach((link) => {
        link.removeEventListener('click', handleClick);
      });
    };
  }, [navigate, location.pathname, when, message]);

  /**
   * Function to continue the navigation after confirming in the custom modal.
   * Navigates to the previously clicked link and hides the modal.
   */
  const continueNavigate = () => {
    navigate(prevLocationRef.current);
    setShowModal(false);
  };

  // Return an array with the state of the custom modal, a function to show/hide it, and a function to continue the navigation after confirming
  return [showModal, setShowModal, continueNavigate];
};
