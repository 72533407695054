import { Grid, styled } from "@mui/material";
import { EBox } from "./EBox";
import PropTypes from 'prop-types';
import { ETypography } from "./ETypography";
import { ECircularProgress } from "./ECircularProgress";

export const EGridItemLabelTextBox = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const EGridPL = styled(Grid)(({ theme }) => ({
    padding: '16px 0 0 24px !important'
}));

// INFO: Grid function for label and input, here we can have multiple inputs as well and it's fully responsive.

ELabelInputMainGrid.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
    isfullgrid: PropTypes.bool, //is used for label-children pair laike address, description, where we use full grid(12) instead of 6.
    small_text: PropTypes.string, //is used for the texts which are written along with the label, ex - Salary(per month)
    alignlabel: PropTypes.string, // using this we can align the label.
    disableColon: PropTypes.bool, // using this we can control colon's visibility.
    labelClassname: PropTypes.string, //we can pass any required className to the label.
    labelPT: PropTypes.string, //we can pass any required padding Top to th e label grid, for large screens(lg, xl) only.
    childrenClassname: PropTypes.string, //we can pass any required className to the children.
    isNotForm:PropTypes.bool, //This can be used in detail vie pages, where we need labels to be bold in all screen size
    isModal:PropTypes.bool, //isModal={true} with isfullgrid={true} will make adjustments to the full grid i.e according to Modals.
};

const lgNotFullGridLabel = 5
const lgFullGridLabel = 2.45
const lgNotFullGridChildren = 7
const lgFullGridChildren = 9.55
const lgFullGridLabelForModal = 3.5
const lgFullGridChildrenForModal = 8.5

export function ELabelInputMainGrid(props) {
    const { label, children, small_text, alignlabel, isfullgrid, disableColon, labelClassname, labelPT, childrenClassname, isNotForm, isModal} = props
    const labelalignment = alignlabel ? alignlabel : 'align-items-start'
    const colonDisplay = disableColon ? 'none' : 'block';
    const xs_sm_md_colon_display='none' //this is the value of display in xs, sm, and lg
    const labelPaddingTop= labelPT?labelPT:isNotForm?'0px':'1rem'

    //INFO: below is the conditions for isfullgrid={true} & isModal={true}
    const lgLabel=!isfullgrid ? lgNotFullGridLabel : (isModal? lgFullGridLabelForModal: lgFullGridLabel) 
    const lgChildren=!isfullgrid ? lgNotFullGridChildren : (isModal? lgFullGridChildrenForModal: lgFullGridChildren)

    //INFO: The below grid values are to adjust label-childern grid item values.
    const xlLabel=isModal ? lgLabel: isfullgrid? lgLabel-0.73: lgLabel-1.5
    const xlChildren=isModal? lgChildren: isfullgrid? lgChildren+0.73:lgChildren+1.5

    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={lgLabel} xl={xlLabel}  className={`display-flex ${labelalignment}`} 
            sx={{ justifyContent: 'space-between', wordBreak: 'break-word', paddingTop:{lg:labelPaddingTop,md:'0px'}}}>
                
                <ETypography className={labelClassname} sx={{ fontWeight: { xs: 600, sm: 600, md: 600, lg: (isNotForm?600:400) }, marginBottom:{md: '4px', sm:'4px'}}}> {label} {/* Label */}
                {/*Small Text Code Below*/}
                {small_text && <span className="small-text-label-grid">{small_text}</span>} 
                </ETypography>
                {/*Colon Below*/}
                <EBox sx={{ display: { xs: xs_sm_md_colon_display, sm: xs_sm_md_colon_display, md: xs_sm_md_colon_display, lg: colonDisplay, xl: colonDisplay } }}>
                :</EBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={lgChildren} xl={xlChildren}
                className={` ${childrenClassname} + ELabelInput`}//font-size-18px-without-imp
                sx={{ paddingLeft: { lg: '0.8rem' }}}>
                {children}
            </Grid>
        </Grid>
    );
}

//Grid with Loading ( ECircularProgress )
LoadingGrid.propTypes = {
    sx: PropTypes.object,//INFO: The sx written in the function will be applied to grid container
    isPage:PropTypes.bool,//INFO: this variable will decide to resize the grid container height to 50vh if true
    size:PropTypes.oneOf(['xl','sm']),//INFO: This specifies the size of loader, by default it'll be 'sm'
};
export function LoadingGrid ({size='sm',isPage=false,sx={}}) {
    const loaderSize= size=='xl'?'5rem':'3rem'
    const height= isPage?'50vh':'100%'
    return (
        <Grid container sx={{...sx, height: height}} className={'align-center'}>
            <Grid item md={12} sm={12} xs={12} className='px-16px justify-content-center display-flex'>
                <ECircularProgress color="primary" size={loaderSize}/>
            </Grid>
        </Grid>
    )
}