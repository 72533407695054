import React from "react";
import { Grid } from "@mui/material";
import moment from "moment";
import FileBoxIcon from "@iconify/icons-material-symbols/file-present-outline-rounded";
import { ECard } from "../../BaseComponents/ECard";
import { ETypography, ETypographyCardHeading } from "../../BaseComponents/ETypography";
import { EIcon } from "../../BaseComponents/EIcon";
import { EDivider, EDividerCard } from "../../BaseComponents/EDivider";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EBox } from "../../BaseComponents/EBox";
import { NIL } from "../../constants";

function TalentReleasedDetail(props) {
  const { employee } = props;


  //   Function to get transfer type
  function getTransferType(type) {
    switch (type) {
      case "1":
        return "Release";
      case "2":
        return "Resignation";
      default:
        return "-";
    }
  }
  return (
    <>
      <ECard className="Bg-card4 borderColor-staticBorder7 mb-16px">
        <Grid container spacing={2}>

          {/* Released date */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ELabelInputMainGrid label={"Released Date"} isNotForm={true}>
              {employee?.employeeReleaseInfo?.created_at
                ? moment(employee?.employeeReleaseInfo?.created_at).format("DD/MM/YYYY")
                : NIL}
            </ELabelInputMainGrid>
          </Grid>

          {/* Released By */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ELabelInputMainGrid label={" Released By"} isNotForm={true}>
              {employee?.updatedBy?.fullname
                ? employee?.updatedBy?.fullname
                : NIL}
            </ELabelInputMainGrid>
          </Grid>

          {/* Last working day */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ELabelInputMainGrid label={"Last Working Day"} isNotForm={true}>
              {employee?.employeeReleaseInfo?.last_working_date ?
                moment(employee?.employeeReleaseInfo?.last_working_date).format("DD/MM/YYYY")
                : NIL}
            </ELabelInputMainGrid>
          </Grid>

          {/* Transfer type */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ELabelInputMainGrid label={"Transfer Type"} isNotForm={true}>
              {employee?.employeeReleaseInfo?.transfer_type? 
              getTransferType( employee?.employeeReleaseInfo?.transfer_type )
              : NIL}
            </ELabelInputMainGrid>
          </Grid>
        </Grid>
      </ECard>

      {/* Release Information */}
      <ECard className="card_design_1  borderColor-staticBorder7">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="mb-8px"
        >
          <ETypographyCardHeading variant="h5" className="font-size-20px">
            Release Information
          </ETypographyCardHeading>
        </Grid>
        <EDivider className="mb-16px borderColor-staticBorder7" />
        <EBox className="mb-16px ">
          <Grid container spacing={2}>
           
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ELabelInputMainGrid label={"Reason"} isfullgrid={true} isNotForm>
                <ETypography>
                  {employee?.employeeReleaseInfo?.reason
                    ? employee?.employeeReleaseInfo?.reason
                    : NIL}
                </ETypography>
              </ELabelInputMainGrid>
            </Grid>
           
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ELabelInputMainGrid label={"Department"} isNotForm>
                <ETypography>
                  {employee?.departmentInfo?.department_name
                    ? employee?.departmentInfo?.department_name
                    : NIL}
                </ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ELabelInputMainGrid label={"Reporting Manager"} isNotForm>
                <ETypography>
                  {employee?.reportingManager?.fullname
                    ? employee?.reportingManager?.fullname
                    : NIL}
                </ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ELabelInputMainGrid label={"Attachment"} isNotForm>
                {employee?.employeeReleaseInfo?.attachment_file ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={employee?.employeeReleaseInfo?.attachment_file}
                  >
                    <EIcon icon={FileBoxIcon} width={23} height={23} />
                  </a>
                ) : (
                  NIL
                )}
              </ELabelInputMainGrid>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ELabelInputMainGrid label={"NOC Document"} isNotForm>
                {employee?.employeeReleaseInfo?.noc_file ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={employee?.employeeReleaseInfo?.noc_file}
                  >
                    <EIcon icon={FileBoxIcon} width={23} height={23} />
                  </a>
                ) : (
                  NIL
                )}
              </ELabelInputMainGrid>
            </Grid>
          </Grid>
        </EBox>
      </ECard>
    </>
  );
}

export default TalentReleasedDetail;
