import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import base64 from 'base-64';
import { ECardBirthday } from "../BaseComponents/ECard";
import { EImage, EAbsoluteImage } from "../BaseComponents/EImage";
import Page from "../BaseComponents/EPage";
import { ETypography, ELabel } from "../BaseComponents/ETypography";
import { GetTalentDetail } from "../action/TalentActions";
import { LoadingGrid } from "../BaseComponents/EGrid";
import { isBirthdayToday } from "../utils/CommonFunctions";

const WISH = "Happy birthday! May this year be filled with love, laughter, and unforgettable memories."

function Birthday(props) {

  const { auth } = props

  const theme = useTheme()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { employeeProfileLoading, employeeProfileData } = useSelector((state) => state.EmployeeReducer);

  useEffect(() => {
    if (auth.authtoken && params.id) {
      return dispatch(GetTalentDetail(auth.authtoken, base64.decode(params.id), navigate, (res) => {
        if (!res?.data) return navigate("/")
        if (!isBirthdayToday(res?.data?.employeeInfo?.personal_dob)) return navigate("/")
      }));
    }
  }, [params?.id, auth.authtoken]);

  if (employeeProfileLoading) {
    return (
      <LoadingGrid sx={{ alignItems: 'center' }} />
    )
  }

  return (
    <Page title="Birthday" sx={{ height: '100%', p: '16px !important' }}>
      <ECardBirthday>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute',
            top: { md: '18%', xs: '25%' },
          }}
        >
          <EImage
            alt='Happy Birthday'
            src="/assets/images/birthday/happy_birthday.svg"
            sx={{
              width: { md: '298px', xs: '195px' }
            }}
          />
          <EImage
            alt='Birthday Gifts'
            src="/assets/images/birthday/gifts.gif"
            sx={{
              width: { md: '317px', xs: '207px' },
              marginTop: { md: '-60px', xs: '-10px' },
              marginBottom: { md: '-50px', xs: '-10px' },
            }}
            loading="eager"
          />
          <Box sx={{ textAlign: 'center', paddingLeft: '33px', paddingRight: '33px', maxWidth: '565px' }}  >
            <ETypography as={"h2"} sx={{ color: theme.palette.text.default, fontSize: { md: 35, xs: 24 } }} >{employeeProfileData?.fullname}</ETypography>
            <ELabel sx={{ color: theme.palette.text.default, fontSize: { md: 20, xs: 14 } }}>{WISH}</ELabel>
          </Box>
        </Box>
        {/* Edge Images */}
        <EAbsoluteImage
          alt='birthday background top left'
          src="/assets/images/birthday/bg_top_left.svg"
          className="bg_top_left"
          sx={{
            top: 0,
            left: 0,
            width: { xs: '37%' }
          }}
        />
        <EAbsoluteImage
          alt='birthday background top right'
          src="/assets/images/birthday/bg_top_right.svg"
          className="bg_top_right"
          sx={{
            top: 0,
            right: { md: 0, xs: '-8.75%' },
            width: { xs: '37%' }
          }}
        />
        <EAbsoluteImage
          alt='birthday background bottom left'
          src="/assets/images/birthday/bg_bottom_left.svg"
          bottom={0}
          left={0}
          className="bg_bottom_left"
          sx={{
            bottom: { md: 0, xs: '-55px' },
            left: 0,
            width: { md: '30%', xs: '52%' }
          }}
        />
        <EAbsoluteImage
          alt='birthday background bottom right'
          src="/assets/images/birthday/bg_bottom_right.svg"
          className="bg_bottom_right"
          sx={{
            bottom: { md: 0, xs: '-3%' },
            right: { md: 0, xs: '-40px' },
            width: { md: '20%' }
          }}
        />
        {/* Balloon Images */}
        <EAbsoluteImage
          alt='Birthday Balloon Yellow'
          src="/assets/images/birthday/balloon1.svg"
          sx={{
            top: '23.5%',
            left: '78px',
            [theme.breakpoints.down("md")]: {
              top: '16%',
              left: '61px',
              width: '52px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Birthday Balloon Red'
          src="/assets/images/birthday/balloon2.svg"
          sx={{
            top: '334px',
            left: '184px',
            [theme.breakpoints.down("md")]: {
              top: '30.5%',
              left: '21%',
              width: '48px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Birthday Balloon Blue'
          src="/assets/images/birthday/balloon3.svg"
          sx={{
            top: '24.5%',
            right: '5%',
            [theme.breakpoints.down("md")]: {
              top: '25%',
              right: '13%',
              width: '65px',
            },
          }}
        />
        {/* Star Images */}
        <EAbsoluteImage
          alt='Blue Star'
          src="/assets/images/birthday/star1.svg"
          sx={{
            top: '20.5%',
            left: '198px',
            [theme.breakpoints.down("md")]: {
              top: '14%',
              left: '125px',
              width: '12px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Star'
          src="/assets/images/birthday/star2.svg"
          sx={{
            top: '37.5%',
            left: '137px',
            [theme.breakpoints.down("md")]: {
              top: '30%',
              left: '15%',
              width: '13px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Pink Star'
          src="/assets/images/birthday/star3.svg"
          sx={{
            top: '47.5%',
            right: '77px',
            [theme.breakpoints.down("md")]: {
              top: '18%',
              right: '30%',
              width: '13px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Star'
          src="/assets/images/birthday/star2.svg"
          sx={{
            top: '69.5%',
            left: '10%',
            [theme.breakpoints.down("md")]: {
              top: '65%',
              left: '15%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Red Star'
          src="/assets/images/birthday/star4.svg"
          sx={{
            bottom: '17%',
            right: '30%',
            [theme.breakpoints.down("md")]: {
              bottom: '25%',
              right: '27%',
            },
          }}
        />
        {/* Poppers */}
        <EAbsoluteImage
          alt='Blue Popper'
          src="/assets/images/birthday/popper5.svg"
          sx={{
            top: '6%',
            right: '-100%',
            [theme.breakpoints.down("md")]: {
              top: '19%',
              right: '9%',
              width: '8px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Popper'
          src="/assets/images/birthday/popper1.svg"
          sx={{
            top: '6%',
            left: '45%',
            [theme.breakpoints.down("md")]: {
              top: '4%',
              right: '17.5%',
              width: '10px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Red Popper'
          src="/assets/images/birthday/popper2.svg"
          sx={{
            top: '16%',
            right: '40%',
            [theme.breakpoints.down("md")]: {
              top: '10%',
              right: '37%',
              width: '13px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Popper'
          src="/assets/images/birthday/popper1.svg"
          sx={{
            bottom: '37%',
            right: '10%',
            [theme.breakpoints.down("md")]: {
              bottom: '33%',
              right: '8%',
              width: '11.5px',
            },
          }}
        />
        <EAbsoluteImage
          alt='Pink Popper'
          src="/assets/images/birthday/popper3.svg"
          sx={{
            bottom: '20%',
            left: '21%',
            [theme.breakpoints.down("md")]: {
              width: '11.5px',
              bottom: '21%',
              left: '20%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Pink Popper'
          src="/assets/images/birthday/popper3.svg"
          sx={{
            top: '-100%',
            [theme.breakpoints.down("md")]: {
              width: '11.5px',
              top: '48%',
              left: '20%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Pink Popper'
          src="/assets/images/birthday/popper3.svg"
          sx={{
            top: '-100%',
            [theme.breakpoints.down("md")]: {
              width: '11.5px',
              top: '38%',
              right: '29%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Popper'
          src="/assets/images/birthday/popper4.svg"
          sx={{
            bottom: '19%',
            left: '42%',
            [theme.breakpoints.down("md")]: {
              bottom: '19%',
              left: '42%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Red Popper'
          src="/assets/images/birthday/popper2.svg"
          sx={{
            bottom: '10%',
            left: '22%',
            [theme.breakpoints.down("md")]: {
              bottom: '10%',
              left: '22%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Red Popper'
          src="/assets/images/birthday/popper2.svg"
          sx={{
            bottom: '-100%',
            [theme.breakpoints.down("md")]: {
              bottom: '33%',
              right: '25%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Popper'
          src="/assets/images/birthday/popper4.svg"
          sx={{
            bottom: '10%',
            left: '52%',
            [theme.breakpoints.down("md")]: {
              bottom: '10%',
              left: '52%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Pink Popper'
          src="/assets/images/birthday/popper3.svg"
          sx={{
            bottom: '10%',
            right: '19.25%',
            [theme.breakpoints.down("md")]: {
              width: '11.5px',
              bottom: '7%',
              right: '50%',
            },
          }}
        />
        <EAbsoluteImage
          alt='Yellow Popper'
          src="/assets/images/birthday/popper6.svg"
          sx={{
            bottom: '23%',
            right: '6%',
            [theme.breakpoints.down("md")]: {
              width: '10px',
              bottom: '52%',
              right: '17%',
            },
          }}
        />
      </ECardBirthday>
    </Page>
  );
}

export default Birthday;
