import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useGreetings = ()=>{
    const auth = useSelector((state) => state.authReducer);
    const [greetMsg, setGreetMsg] = useState("");

    let greetings = () => {
        var today = new Date();
        var curHr = today.getHours();
        if (curHr >= 6 && curHr < 12) {
          return "Good Morning";
        } else if (curHr >= 12 && curHr <= 15) {
          return "Good Afternoon";
        } else {
          return "Good Evening";
        }
      };

      useEffect(() => {
        if (auth?.authtoken) {
          setGreetMsg(greetings());
        }
      }, [auth?.authtoken]);

    return { greetMsg }
}