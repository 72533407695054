import { Avatar,styled } from "@mui/material";


export const EAvatarProfile = styled(Avatar)(({ theme }) => ({
    width: 150, 
    height: 150,
    borderColor: theme.palette.border.light
}));

export const EAvatar = styled(Avatar)(({ theme }) => ({

}));

export const EAvatarHrDashboardBirthdayToday = styled(Avatar)(({ theme }) => ({
    width: '75px',
    height: '75px',
    flex: '0 1 auto',
    order: 0,
}));

export const EAvatarHrDashboardBirthdayTalent = styled(Avatar)(({ theme }) => ({
    width: '55px',
    height: '55px',
    flex: '0 1 auto',
    order: 0,
}));

export const EAvatarHrDashboardBirthdayUpcoming = styled(Avatar)(({ theme }) => ({
    width: '56px',
    height: '56px',
    flex: '0 1 auto',
    order: 0,
    backgroundColor: theme.palette.background.staticGreyHR
}));

export const EAvatarHrDashboardLeaveIconParent = styled(Avatar)(({ theme }) => ({
    width: '28px',
    height: '28px',
    flex: '0 1 auto',
    marginRight:'3px',
    order: 0,

    [theme.breakpoints.up('md')]: {
        width: '36px',
        height: '36px',
        marginRight:'10px',
    },

}));

export const EAvatarHrDashboardNewPaymentRequest = styled(Avatar)(({ theme }) => ({
    width: '48px',
    height: '48px',
    flex: '0 1 auto',
    order: 0,
}));

