
import {
    EMPLOYEE_FORM_SUCCESS, EMPLOYEE_FORM_FAILURE, EMPLOYEE_FORM_LOADING
} from '../constants';

const INITIAL_STATE = {

  employeeAddData: {},
  employeeAddSuccess: false,
  employeeAddLoading: false,
  employeeAddMessage: null,
};

const EmployeeFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
      case EMPLOYEE_FORM_LOADING:
      return  {
        employeeAddLoading: true,
        employeeAddData: {},
      };

    case EMPLOYEE_FORM_SUCCESS:
      return {
        employeeAddLoading: false,
        employeeAddSuccess: true,
        employeeAddData: action.payload.data,
        employeeAddMessage: action.payload.message,
      };

    case EMPLOYEE_FORM_FAILURE:
      return  {
        employeeAddLoading: false,
        employeeAddSuccess: false,
        employeeAddMessage: action.payload.message,
        employeeAddData: {},
      };
      
    default:
      return state;
  }
};

export default EmployeeFormReducer;
