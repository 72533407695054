import {
    DEPARTMENT_LIST_FAILURE, DEPARTMENT_LIST_SUCCESS, DEPARTMENT_LIST_LOADING, DEPARTMENT_FAILURE, DEPARTMENT_LOADING, DEPARTMENT_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    departmentListData: {},
    departmentListSuccess: false,
    departmentListLoading: false,
    departmentListMessage: null,
    departmentData: {},
    departmentSuccess: false,
    departmentLoading: false,
    departmentMessage: null,

};

const DepartmentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {



        case DEPARTMENT_LOADING:
            return {
                departmentLoading: true,
                departmentData: {}
            };

        case DEPARTMENT_SUCCESS:
            return {
                departmentLoading: false,
                departmentSuccess: true,
                departmentData: action.payload.data,
                departmentMessage: action.payload.message,
            };

        case DEPARTMENT_FAILURE:
            return {
                departmentLoading: false,
                departmentSuccess: false,
                departmentMessage: action.payload.message,
            };

        // FOR DEPARTMENT LIST 

        case DEPARTMENT_LIST_LOADING:
            return {
                departmentListLoading: true,
                departmentListData: {}
            };

        case DEPARTMENT_LIST_SUCCESS:
            return {
                departmentListLoading: false,
                departmentListSuccess: true,
                departmentListData: action.payload.data,
                departmentListMessage: action.payload.message,
            };

        case DEPARTMENT_LIST_FAILURE:
            return {
                departmentListLoading: false,
                departmentListSuccess: false,
                departmentListMessage: action.payload.message,
                departmentListData: {}
            };

        default:
            return state;
    }
};

export default DepartmentReducer;
