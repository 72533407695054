import {  SERVER_URL } from "../constants";
import axios from 'axios';
import { Errors } from "../BaseComponents/EToast";

export function DownLoadFile(token, url = null, file_name = null, params = null) {  

    axios({
        method: 'GET',
        url: `${SERVER_URL}/${url}`,
        params,
        crossDomain: true,
        responseType: 'blob', // important        
        headers: {
          "Authorization": 'Bearer ' + token,       
        },
    }).then((response) => {
        
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];        
        let fileName = file_name ? file_name :'report';  
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2)
                fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

    }).catch((error) => {    
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error?.response?.statusText}</span>`,
          })
        }
      });
}
