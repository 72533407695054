import Page from "../BaseComponents/EPage";
import { ETypography } from "../BaseComponents/ETypography";

function PrivacyPolicy() {
  return (
    <Page title="Privacy Policy">
      {/* Heading */}
      <ETypography variant="h3" className="py-16px text-align">
        Privacy Policy
      </ETypography>

      {/* Content */}
      <ETypography className="py-16px">
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING OUR
        WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE
        BOUND TO ALL THE TERMS OF THIS PRIVACY POLICY AND OUR WEBSITE TERMS AND
        CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS, EXIT THIS PAGE AND DO
        NOT ACCESS OR USE THE WEBSITE.
      </ETypography>
      <ETypography className="py-16px">
        At entera.in, privacy issues are of high priority. We regard and are
        resolved to ensure your protection. By using our website entera.in, you
        are accepting the practices described in the Privacy Policy. This
        Privacy Policy describes how we handle your personal information for our
        services on our website. Subject to the requirements of applicable law,
        we strive to provide a consistent set of privacy practices.
      </ETypography>

      <ETypography className="py-16px">
        Should we ask you to provide any information by which you can be
        identified when using this website, then you can be assured that it will
        only be used in accordance with this privacy statement. By accepting the
        Privacy Policy and the Terms & Conditions, you expressly consent to our
        collection, storage, use and disclosure of your personal information as
        described in this Privacy Policy.
      </ETypography>

      <ETypography className="py-16px">
        If you have any questions about this Privacy Policy, then you may please
        write to us at info  <a href="https://entera.in/">www.entera.in</a>
      </ETypography>

      <br/>  
      <h2>Personal Information</h2>

      <ETypography className="py-16px">
        To avail the Services of our Website, you understand and agree that you
        may be asked to provide Personal Information as part of the registration
        process and at other points in using the services, and that we will
        store information that you provide. At various points and/or stages in
        using and/or availing the Services you may also need to provide
        additional Personal Information so that we can perform tasks you request
        in the Services. Subject to the requirements of applicable law, we shall
        not at any time without your permission, disclose any specific Personal
        Information which is gathered by us as per this Policy, to any third
        parties.
      </ETypography>

      <br/>  

      <h2>Information Collection And Use</h2>

      <ETypography className="py-16px">
        We may use third party payment gateway services to collect payment from
        you for our subscription services that we may come up with in the
        future, if you opt for online payment on our website/mobile app. We
        don’t have any control over the card detail collection part and we don’t
        store any of your details pertaining to payment mode/card you have opted
        for in our database. We don’t get any card details, in return, from the
        payment gateway after successful/failed transactions on our
        website/mobile app. We are not liable for any type of misusages, if any,
        that happens on your card after making payment on our website/mobile
        app.
      </ETypography>

      <br/>  
      <h2>Links To Other Websites</h2>
      <ETypography className="py-16px">
        At times, we may link to other sites. Once you have used these links to
        leave our site, you should note that we do not have any control over the
        other websites. Therefore, we cannot be responsible for the protection
        and privacy of any information, which you provide whilst visiting such
        sites and this privacy statement does not govern such sites. You should
        exercise caution and look at the privacy statement applicable to the
        website in question.
      </ETypography>

      <br/>  
      <h2>Cookies</h2>

      <ETypography className="py-16px">
        To facilitate and customize your experience with the website, we may
        store cookies on your computer. Cookies are files that your web browser
        puts on your computer when you visit a website. We may use cookies to
        record how often you visit our site, and what pages you go to on our
        site. The cookie itself does not have personal information in it, so no
        one except us can figure out who you are from our cookie. We use cookies
        to save your time while using the website, remind us who you are, and
        track and target your interests in order to provide a customized
        experience. Cookies also allow us to collect non-personally identifiable
        information from you, like which pages you visited and what links you
        clicked on. Use of this information helps us to create a more
        user-friendly experience for all visitors. You can choose to accept or
        decline cookies. Most web browsers automatically accept cookies, but you
        can usually modify your browser setting to decline cookies, if you
        prefer. This may, however, prevent you from taking full advantage of the
        website.
      </ETypography>

      <br/> 

      <h2>Data Sharing</h2>

      <ETypography className="py-16px">
        We may share your information with service providers who may provide
        some services through us. We may share your information with them, so
        that they can contact you regarding a transaction. However, we will only
        share such information if you engage in an activity, and then only to
        those involved in that activity. We may share your information with
        authorized third party service providers. We provide some of our
        services through third parties. These “Third Party Service Providers”
        perform functions on our behalf, like sending out and distributing our
        administrative and promotional emails. We may share your other
        information with such Service Providers to send postal or email,
        administer contests or sweepstakes, remove repetitive information on
        customer lists, analyze data, provide marketing assistance, provide
        search results and links, operate the Website, troubleshoot, and provide
        customer service. We may also collect personal information from
        individuals and companies (“Affiliates”) with whom we have business
        relationships and may have to also share their information with Service
        Providers to accomplish our administrative tasks. Information collected
        shall not be used or disclosed for purposes other than those for which
        the information was collected, except with the consent of the individual
        or as required by law. Personal information shall be retained only as
        long as necessary for the fulfillment of those purposes. If we have
        reason to believe that disclosing your personal identification is
        required by law, or is otherwise reasonable to protect someone from
        possible injury, we may do so. Though we make every effort to preserve
        your privacy we may need to disclose personal information when required
        by law wherein we have a good-faith and belief that such action is
        necessary to comply with a current judicial proceeding, a court order or
        legal process served on our website.
      </ETypography>

      <br/>  

      <h2>Log Data</h2>

      <ETypography className="py-16px">
        Whenever you browse our website, our servers automatically record the
        information sent by your browser. These server logs may include
        information such as your web request, Internet Protocol address, browser
        type, browser language, the date and time of your request and one or
        more cookies that may uniquely identify your browser. We use your IP
        address to help diagnose problems with our server, to administer and
        tune the operation of our site, and to log your browser type and
        referring URL information. Your IP address may also be used in
        collecting statistical data such as number of 'hits' and 'page views'
        which is gathered for broad usage information only. None of this
        information can identify you personally. In addition, we may use third
        party services such as Google Analytics that collect, monitor and
        analyze this.
      </ETypography>

      <br/>  
      <h2>Communications</h2>
      <ETypography className="py-16px">
        We may use your Personal Information to contact you with newsletters,
        marketing or promotional materials, email, SMS and other information
        that might be relevant to you.
      </ETypography>
 
      <br/>  
      <h2>Security</h2>

      <ETypography className="py-16px">
        We are committed to ensuring that your information is secure. In order
        to prevent unauthorized access or disclosure, we have put in place
        suitable physical, electronic and managerial procedures to safeguard and
        secure the information we collect online. Unfortunately, no data
        transmission over the Internet or any wireless network can be guaranteed
        to be 100% secure. As a result, while we employ commercially reasonable
        security measures to protect data and seek to partner with companies,
        which do the same, we cannot guarantee the security of any information
        transmitted to or from the website, and are not responsible for the
        actions of any third parties that may receive any such information.
      </ETypography>

      <br/> 

      <h2>Changes To This Privacy Policy</h2>

      <ETypography className="py-16px">
        The Privacy Policy may be changed at any time upon our sole discretion
        and without prior notice to you. The revised Privacy Policy will be
        effective immediately when posted on the Website. It is your
        responsibility to review the Website and the Privacy Policy periodically
        to learn of any revisions to this Privacy Policy. Your continued use of
        the Website after the effectiveness of such revisions will constitute
        your acknowledgment and acceptance of the terms of the revised Privacy
        Policy.
      </ETypography>
    </Page>
  );
}

export default PrivacyPolicy;
