import {  SCHEDULE_CALL_SLOT_LOADING, SCHEDULE_CALL_SLOT_SUCCESS, SCHEDULE_CALL_SLOT_FAILURE } from '../constants';


const INITIAL_DATA = {
    callSlotData: {},
    // onBoardEmailSent: false,
    callSlotSuccess: false,
    callSlotLoading: false,
    callSlotMessage: null,

};

const ScheduleACallReducer = (initialData = INITIAL_DATA, action) => {
    
    switch (action.type) {    

        case SCHEDULE_CALL_SLOT_LOADING:
            return {
                callSlotData: {},
                callSlotSuccess: false,
                callSlotLoading: true,
                callSlotMessage: null,
            };

        case SCHEDULE_CALL_SLOT_SUCCESS:
            return {
                callSlotLoading: false,
                callSlotSuccess: true,
                callSlotData: action.payload,
                callSlotMessage: action.payload.message,
            };

        case SCHEDULE_CALL_SLOT_FAILURE:
            return {
                callSlotMessage: action.payload.message,
                callSlotData: {},
                callSlotSuccess: false,
                callSlotLoading: false,
            };

        default:
            
            return initialData;
    }
};

export default ScheduleACallReducer;
