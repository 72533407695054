
import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Container, Grid, Stack } from '@mui/material';
import AuthPage from "../../BaseComponents/EAuthPage";
import SubscriptionSuccess from "../../PageComponents/Auth/SubscriptionSuccess";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";

// const SINGNUP_STEP = 1; //INFO: commenting this since it's of no use as of now
// const SUBSCRIPTION_STEP = 2; //INFO: commenting this since it's of no use as of now

const Subscription = () => {
    const onBoardingData = useSelector(state => state.OnBoardingReducer);
    // const planList = useSelector(state => state.PlanReducer.planListData);
    const navigate = useNavigate();

    // const dispatch = useDispatch()
    //checking signup is done or not
    useEffect(() => {

        if(!onBoardingData.onBoardSuccess){
            navigate('/sign-up');
        }
        // if (![SINGNUP_STEP, SUBSCRIPTION_STEP]
        //     .includes(onBoardingData.onBoardData?.onboarding_step)) {
        //     navigate('/sign-up');
        // }
    }, [onBoardingData.onBoardSuccess]);




    return (
        <AuthPage title="Product Subscription">
            {/* <Grid conatiner px={10}>
            <Stack
          direction="row"
          spacing={2}
          paddingTop={2}
          justifyContent="flex-end"
          className="pb-24px"
        >
          <EButtonOutlined onClick={()=>navigate('/sign-up')} size="large" variant="outlined" >
            {" "}
            Back{" "}
          </EButtonOutlined>
          <EButton onClick={()=>navigate('/login')} size="large" type="submit" variant="contained">
          Login
          </EButton>
        </Stack>
            </Grid> */}
   
               
            <RootStyle>
          

                <SubscriptionSuccess
                    subscription={onBoardingData.onBoardData} />

                {/* {onBoardingData && Object.keys(onBoardingData.onBoardData).length > 0 && (
                    <>
                        {onBoardingData.onBoardData?.onboarding_step == SINGNUP_STEP &&
                            <SubScriptionPayment
                                subscription={onBoardingData.onBoardData} planList={planList} />}

                        {onBoardingData.onBoardData?.onboarding_step == SUBSCRIPTION_STEP &&
                            <SubscriptionSuccess 
                            subscription={onBoardingData.onBoardData} />}

                    </>
                )} */}
            </RootStyle>
        </AuthPage>
    );
};

const RootStyle = styled(Container)(({ theme }) => ({
    paddingTop: '8vh'
}));



export default Subscription;