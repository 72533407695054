import axios from "axios";
import { Success, Errors } from "../BaseComponents/EToast";
import { GET_ACCOUNT_SETTING_API, SERVER_URL } from "../constants";
import { GetCompanySetting, GetSetting } from "./CompanySettingAction";

export function UpdateBillingInfo(token, Data, close = null) {
  const data = new FormData();
  data.append("fname", Data.fname);
  data.append("lname", Data.lname);
  data.append("company_name", Data.company_name);
  if(Data.gst){
    data.append("company_gst", Data.gst);
  }
  data.append("company_email", Data.email);
  data.append("company_phone", Data.phone_no);
  data.append("address", Data.address);
  data.append("state_id", Data?.state?.id);
  data.append("city_id", Data?.city?.id);
  data.append("pin_code", Data.pin_code);
  return (dispatch) => {
    axios({
      method: "post",
      url: `${SERVER_URL}/setting/set-billing-setting`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
          dispatch(GetSetting(token,GET_ACCOUNT_SETTING_API));
          if (close) {
            close();
          }
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          });

          if (close) {
            close();
          }
        }
      });
  };
}
